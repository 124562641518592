import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { getFormats } from '@/utils/FormatarDados';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { validadoresFrontEnd } from 'zlib-utils';
var RelProcessosAdmPendentes = /** @class */ (function (_super) {
    __extends(RelProcessosAdmPendentes, _super);
    function RelProcessosAdmPendentes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rsProcessos = ['Integrações Pendentes', 'Contratos Não Conferidos'];
        _this.txtProcesso = '';
        _this.pesquisa = {
            periodo: []
        };
        _this.registros = [];
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'Matrícula', value: 'IDMatricula' },
            { text: 'Nome', value: 'Nome' },
            { text: 'Data da Matrícula', value: 'DataMatricula' }
        ];
        return _this;
    }
    Object.defineProperty(RelProcessosAdmPendentes.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelProcessosAdmPendentes.prototype, "getFormats", {
        get: function () { return getFormats; },
        enumerable: false,
        configurable: true
    });
    RelProcessosAdmPendentes.prototype.btPesquisar = function () {
        this.getRelatorio();
    };
    RelProcessosAdmPendentes.prototype.getRelatorio = function () {
        var _this = this;
        var query = "\n      relProcessosAdministrativosPendentes(tipo: \"" + this.txtProcesso + "\") {\n        IDMatricula\n        Nome\n        DataMatricula\n      }\n    ";
        this.clsApollo.query(this, query, 'relProcessosAdministrativosPendentes', 'Aguarde...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    RelProcessosAdmPendentes = __decorate([
        Component
    ], RelProcessosAdmPendentes);
    return RelProcessosAdmPendentes;
}(Vue));
export default RelProcessosAdmPendentes;
