import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '../../utils/ClsApolloClient';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { AUTH_TOKEN } from '../../vue-apollo';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { PAGINA_INICIAL_EMDESENVOLVIMENTO, PAGINA_INICIAL_PADRAO } from '@/config/Menu';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
import ClsUtils from 'zlib-utils/ClsUtils';
import store from '@/store';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApolloClient = new ClsApolloClient();
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.exibirSenha = false;
        _this.registro = {
            // login: EMDESENVOLVIMENTO ? 'Zanatta' : '',
            // senha: EMDESENVOLVIMENTO ? 'Teste123' : '',
            // Login de RH
            //login: EMDESENVOLVIMENTO ? 'Zanatta' : '',
            // senha: EMDESENVOLVIMENTO ? 'Teste123' : ''
            // Login de Usuário
            login: EMDESENVOLVIMENTO ? 'Fernanda' : '',
            senha: EMDESENVOLVIMENTO ? 'Fer35121919' : ''
        };
        return _this;
    }
    App.prototype.mounted = function () {
        var tmpToken = localStorage.getItem(AUTH_TOKEN) || '';
        if (tmpToken && !LoginStoreModule.logado) {
            this.logarPorToken();
        }
    };
    Object.defineProperty(App.prototype, "validadoresFrontEnd", {
        get: function () {
            return validadoresFrontEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    App.prototype.logarPorToken = function () {
        var _this = this;
        var mutation = "\n      logarPorToken {\n        ok\n        mensagem\n        nome\n        token\n        tipo\n        idUsuario\n      }\n    ";
        this.status = StatusFormType.processando;
        this.clsApolloClient.mutation(this, mutation, 'logarPorToken', 'Verificando Login...').then(function (rs) {
            if (rs.ok) {
                store.dispatch('logar', rs).then(function (rs) {
                    localStorage.setItem(AUTH_TOKEN, rs.token);
                    store.dispatch('updateAll', _this).then(function () {
                        if (EMDESENVOLVIMENTO && PAGINA_INICIAL_EMDESENVOLVIMENTO.length > 0) {
                            _this.$router.push(PAGINA_INICIAL_EMDESENVOLVIMENTO);
                        }
                        else {
                            _this.$router.push(PAGINA_INICIAL_PADRAO);
                        }
                    });
                });
            }
            else {
                _this.status = StatusFormType.inicial;
            }
        }).catch(function (err) {
            console.log('ERRO....', err);
        });
    };
    App.prototype.logar = function () {
        var _this = this;
        var clsUtils = new ClsUtils();
        // @ts-ignore
        if (this.$refs.form.validate()) {
            var logar = "\n        logar (login: " + clsUtils.ConverterEmGql(this.registro) + ") {\n            ok\n            mensagem\n            nome\n            tipo\n            idUsuario\n            token\n          }\n      ";
            this.status = StatusFormType.processando;
            this.clsApolloClient.mutation(this, logar, 'logar', 'Verificando Usuário e Senha...').then(function (rs) {
                if (rs.ok) {
                    store.dispatch('logar', rs).then(function (rs) {
                        localStorage.setItem(AUTH_TOKEN, rs.token);
                        store.dispatch('updateAll', _this).then(function () {
                            if (EMDESENVOLVIMENTO && PAGINA_INICIAL_EMDESENVOLVIMENTO.length > 0) {
                                _this.$router.push(PAGINA_INICIAL_EMDESENVOLVIMENTO);
                            }
                            else {
                                _this.$router.push('/');
                            }
                        });
                    });
                }
                else {
                    MensagemStoreModule.exibirMensagem({
                        mensagem: 'Erro ao Logar!',
                        tipo: TipoMensagemType.erro,
                        titulo: 'Erro!!!'
                    });
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                MensagemStoreModule.exibirMensagem({
                    mensagem: 'Erro ao Logar!',
                    tipo: TipoMensagemType.erro,
                    titulo: 'Erro no Servidor!!!'
                });
                console.log('ERRO 05', err);
            });
        }
    };
    App = __decorate([
        Component
    ], App);
    return App;
}(Vue));
export default App;
