import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClsApolloClient from '@/utils/ClsApolloClient';
import LeadNovoAcompanhamento from '../leads/LeadNovoAcompanhamento.vue';
var AgendaEventoCls = /** @class */ (function (_super) {
    __extends(AgendaEventoCls, _super);
    function AgendaEventoCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.txtConcluir = 'Concluir';
        _this.clsApollo = new ClsApolloClient();
        return _this;
        /*
        private getEvents (): void {
      
          let atualizarDadosCliente: string = `
            getAgenda (criterio: ${clsUtils.ConverterEmGql( clsUtils.removeNullFromObject( this.pesquisa ) )}) {
              idLeadAgenda
              idUsuario
              nomeUsuario
              canal
              dataHora
              cancelado
              concluido
              nomeCliente
              idCliente
            }
          `
          this.clsApollo.query<Array<RelatorioLeadAgendamentoInterface>>( this, atualizarDadosCliente, 'getAgenda', 'Pesquisando...' ).then( rs => {
            this.events = []
            rs.forEach( valor => {
              this.events.push( {
                name: valor.nomeUsuario.concat( " - " ).concat( valor.nomeCliente ),
                color: CORES[_.findIndex( CORES, { canal: valor.canal } )].cor,
                start: DateTime.fromISO( <any>valor.dataHora ).toJSDate(),
                end: DateTime.fromISO( <any>valor.dataHora ).toJSDate(),
                timed: true,
                idLeadAgenda: valor.idLeadAgenda
              } )
            } );
          } )
        }
      */
        /*
      
        private get DataStoreModule (): StateDataInterface { return DataStoreModule }
      
      
      
        private registros: Array<RelatorioProducaoInterface> = []
      
        private get getRoles (): typeof getRoles {
          return getRoles
        }
      
        private btPesquisar (): void {
          this.getRelatorio()
        }
      
        private clsApollo: ClsApolloClient = new ClsApolloClient()
      
        private cabecalhos = [
          { text: 'idUsuario', value: 'idUsuario' },
          { text: 'Novo', value: StatusRole.NOVO.toString() },
          { text: 'Tratativa', value: StatusRole.TRATATIVA.toString() },
          { text: 'Negociação', value: StatusRole.NEGOCIACAO.toString() },
          { text: 'Não Atende', value: StatusRole.NAO_ATENDE.toString() },
          { text: 'Incorreto', value: StatusRole.INCORRETO.toString() },
          { text: 'Sem Interesse', value: StatusRole.SEM_INTERESSE.toString() },
          { text: 'Futuro', value: StatusRole.CONTATO_FUTURO.toString() },
          { text: 'Matrícula', value: StatusRole.MATRICULA.toString() },
          { text: 'Total', value: 'Total' },
        ]
      
        private getRelatorio () {
      
          // console.log( this.getRoles.usuario( 1 ) )
      
          if ( this.pesquisa.periodoCadastro && this.pesquisa.periodoCadastro.length == 0 ) {
            delete this.pesquisa.periodoCadastro
          }
      
          let query: string = `
            relFunilPorVendedor(criterio: ${clsUtils.ConverterEmGql( clsUtils.removeNullFromObject( this.pesquisa ) )}) {
              idUsuario
              status
              qtd
            }
          `
      
          this.clsApollo.query<Array<RelatorioProducaoInterface>>( this, query, 'relFunilPorVendedor', 'Aguarde...' ).then( rs => {
            this.registros = this.totalizarRelatorio( rs )
          } ).catch( err => {
            console.log( 'erro: ', err )
          } )
        }
      
        private totalizarRelatorio ( rs: Array<RelatorioProducaoInterface> ): any {
          let obj: any = []
      
          rs.forEach( ( element: any ) => {
      
            let indice = _.findIndex( obj, { idUsuario: element.idUsuario } )
            if ( indice < 0 ) {
              obj.push( { idUsuario: element.idUsuario } )
              indice = obj.length - 1
              obj[indice]['Total'] = 0
            }
      
            obj[indice][element.status] = element.qtd
            obj[indice]['Total'] += element.qtd
      
          } );
      
          return obj
        }
      
        */
    }
    AgendaEventoCls.prototype.salvarCalendario = function () {
        var _this = this;
        console.log("salvarCalendario");
        var mutation = "\n      atualizarLeadAgenda(idLeadAgenda: " + this.eventoSelecionado.idLeadAgenda + ", concluirOuCancelar: \"" + this.txtConcluir + "\") {\n        ok\n        mensagem\n      }\n    ";
        this.clsApollo.mutation(this, mutation, 'atualizarLeadAgenda', 'Atualizando Compromisso...').then(function (rs) {
            _this.$emit("refreshCalendario");
        });
    };
    __decorate([
        Prop({ default: false })
    ], AgendaEventoCls.prototype, "exibirEvento", void 0);
    __decorate([
        Prop({ default: function () { } })
    ], AgendaEventoCls.prototype, "eventoSelecionado", void 0);
    AgendaEventoCls = __decorate([
        Component({
            components: {
                LeadNovoAcompanhamento: LeadNovoAcompanhamento
            }
        })
    ], AgendaEventoCls);
    return AgendaEventoCls;
}(Vue));
export default AgendaEventoCls;
