import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DataStoreModule } from '@/store/modules/DataStore';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import inputDateTime from '@/components/inputDateTime.vue';
import { ClsReset } from '../ClsResets';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusPermitidoVendedorNovosClientes, StatusRole, StatusRoleDescricao, StatusTravadoParaSupervisor } from '@/interfaces/backend/RolesInterfaces';
import { LoginStoreModule } from '../../store/modules/LoginStore';
var LeadNovoAcompanhamentoCls = /** @class */ (function (_super) {
    __extends(LeadNovoAcompanhamentoCls, _super);
    function LeadNovoAcompanhamentoCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.editedItem = (new ClsReset()).novoContato;
        _this.tmpRegistro = __assign({}, _this.editedItem);
        _this.status = StatusFormType.editando;
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    Object.defineProperty(LeadNovoAcompanhamentoCls.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LeadNovoAcompanhamentoCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    LeadNovoAcompanhamentoCls.prototype.onChangeIdLead = function (newValue, oldValue) {
        if (this.editedItem.idLead != newValue || newValue != oldValue) {
            this.resetItem();
        }
    };
    Object.defineProperty(LeadNovoAcompanhamentoCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    LeadNovoAcompanhamentoCls.prototype.mounted = function () {
        this.resetItem();
    };
    LeadNovoAcompanhamentoCls.prototype.resetItem = function () {
        this.editedItem = (new ClsReset()).novoContato;
        this.editedItem.idLead = this.idLead;
        this.tmpRegistro = __assign({}, this.editedItem);
    };
    LeadNovoAcompanhamentoCls.prototype.btConfirmar = function () {
        var _this = this;
        var novoContato = "\n      novoContato(dados: " + clsUtils.ConverterEmGql(this.editedItem) + ") {\n        ok\n        mensagem\n      }    \n    ";
        this.clsApollo.mutation(this, novoContato, 'novoContato', 'Gravando Contato...').then(function (rs) {
            if (rs.ok) {
                _this.resetItem();
                _this.$emit('refresh');
            }
        });
    };
    LeadNovoAcompanhamentoCls.prototype.btCancelar = function () {
        this.resetItem();
    };
    Object.defineProperty(LeadNovoAcompanhamentoCls.prototype, "StatusRolePermitidos", {
        get: function () {
            var _this = this;
            return StatusRoleDescricao.filter(function (valor) {
                return valor.idStatus != StatusRole.NOVO
                    && ((LoginStoreModule.isSupervisor || LoginStoreModule.isAdmin)
                        // || ( LoginStoreModule.isVendedor && StatusTravadoParaSupervisor.includes( valor.idStatus ) )
                        || (LoginStoreModule.isVendedor && StatusPermitidoVendedorNovosClientes.includes(valor.idStatus) && !StatusTravadoParaSupervisor.includes(_this.statusAtual))
                        || (LoginStoreModule.isVendedor && StatusTravadoParaSupervisor.includes(valor.idStatus)));
                // return StatusTravadoParaSupervisor.includes( this.statusAtual ) && !LoginStoreModule.isSupervisor && !LoginStoreModule.isAdmin ? valor.idStatus == StatusRole.NEGOCIACAO : valor.idStatus != StatusRole.NOVO
            });
            /*
        
            return StatusRoleDescricao.filter( ( valor ) => {
              return StatusTravadoParaSupervisor.includes( this.statusAtual ) && !LoginStoreModule.isSupervisor && !LoginStoreModule.isAdmin ? valor.idStatus == StatusRole.NEGOCIACAO : valor.idStatus != StatusRole.NOVO
            } )
        
            */
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: 0 })
    ], LeadNovoAcompanhamentoCls.prototype, "idLead", void 0);
    __decorate([
        Prop({ default: 0 })
    ], LeadNovoAcompanhamentoCls.prototype, "statusAtual", void 0);
    __decorate([
        Watch('idLead')
    ], LeadNovoAcompanhamentoCls.prototype, "onChangeIdLead", null);
    LeadNovoAcompanhamentoCls = __decorate([
        Component({
            components: {
                inputDateTime: inputDateTime
            }
        })
    ], LeadNovoAcompanhamentoCls);
    return LeadNovoAcompanhamentoCls;
}(Vue));
export default LeadNovoAcompanhamentoCls;
