import { __decorate, __extends } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import inputDateRange from '@/components/inputDateRange.vue';
import { DataStoreModule } from '@/store/modules/DataStore';
var leadsPesquisa = /** @class */ (function (_super) {
    __extends(leadsPesquisa, _super);
    function leadsPesquisa() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(leadsPesquisa.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { } })
    ], leadsPesquisa.prototype, "pesquisa", void 0);
    leadsPesquisa = __decorate([
        Component({
            components: {
                inputDateRange: inputDateRange
            }
        })
    ], leadsPesquisa);
    return leadsPesquisa;
}(Vue));
export default leadsPesquisa;
