import { TipoUsuarioRole } from "@/interfaces/backend/RolesInterfaces";
//TODO - Página Inicial Login Aqui...
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/WhatsApp'
export var PAGINA_INICIAL_EMDESENVOLVIMENTO = '/RelProcessosAdmPendentes';
export var PAGINA_INICIAL_PADRAO = '/Agenda';
export var MENU = [
    { icone: 'mdi-account-multiple-plus-outline', texto: 'Clientes', link: '/Clientes', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR, TipoUsuarioRole.USUARIO] },
    { icone: 'mdi-account-details-outline', texto: 'Leads', link: '/Leads', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR] },
    { icone: 'mdi-calendar', texto: 'Agenda', link: '/Agenda', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR, TipoUsuarioRole.USUARIO] },
    { icone: 'mdi-stack-overflow', texto: 'Origens', link: '/Origens', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR] },
    { icone: 'mdi-post-outline', texto: 'Produtos', link: '/Produtos', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR] },
    { icone: 'mdi-account-group-outline', texto: 'Indicações', link: '/IndicacaoView', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR] },
    {
        icone: 'mdi-chevron-down',
        iconeAlt: 'mdi-chevron-up',
        texto: 'Relatórios',
        expandido: true,
        permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR],
        filhos: [
            { icone: 'mdi-trending-up', texto: 'Produtividade', link: '/RelProdutividade', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR] },
            { icone: 'mdi-filter-outline', texto: 'Funil', link: '/RelFunil', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR] },
        ],
    },
    {
        icone: 'mdi-chevron-down',
        iconeAlt: 'mdi-chevron-up',
        texto: 'Financeiro',
        expandido: true,
        permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO],
        filhos: [
            { icone: 'mdi-trending-up', texto: 'Proc. Adm. Pend.', link: '/RelProcessosAdmPendentes', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO] },
            { icone: 'mdi-trending-up', texto: 'Atraso 1a Parcela', link: '/RelPrimeiraParcelaEmAtraso', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO] },
            { icone: 'mdi-trending-up', texto: 'Termos', link: '/RelTermos', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO] },
        ],
    },
    { icone: 'mdi-lock-reset', texto: 'Alterar Senha', link: '/AlterarSenha', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO] },
    { icone: 'mdi-logout', texto: 'Sair', link: '/Logout', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR, TipoUsuarioRole.USUARIO, TipoUsuarioRole.FINANCEIRO] }
];
