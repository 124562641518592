import { __decorate, __extends } from "tslib";
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '..';
export var TipoMensagemType;
(function (TipoMensagemType) {
    TipoMensagemType[TipoMensagemType["erro"] = 1] = "erro";
    TipoMensagemType[TipoMensagemType["aviso"] = 2] = "aviso";
    TipoMensagemType[TipoMensagemType["aguarde"] = 3] = "aguarde";
})(TipoMensagemType || (TipoMensagemType = {}));
// @Module( { namespaced: true, name: 'login' } )
var MensagemStore = /** @class */ (function (_super) {
    __extends(MensagemStore, _super);
    function MensagemStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.titulo = '';
        _this.mensagem = '';
        _this.tipo = TipoMensagemType.aviso;
        _this.exibir = false;
        return _this;
    }
    MensagemStore.prototype.show = function (objMensagem) {
        this.mensagem = objMensagem.mensagem;
        this.titulo = objMensagem.titulo;
        this.tipo = objMensagem.tipo;
        this.exibir = objMensagem.exibir;
    };
    MensagemStore.prototype.exibirMensagem = function (inputMensagem) {
        return {
            mensagem: inputMensagem.mensagem,
            titulo: inputMensagem.titulo,
            tipo: inputMensagem.tipo ? inputMensagem.tipo : TipoMensagemType.aviso,
            exibir: true
        };
    };
    MensagemStore.prototype.ocultarMensagem = function () {
        return {
            mensagem: '',
            titulo: '',
            tipo: TipoMensagemType.aviso,
            exibir: false
        };
    };
    __decorate([
        Mutation
    ], MensagemStore.prototype, "show", null);
    __decorate([
        Action({ commit: 'show' })
    ], MensagemStore.prototype, "exibirMensagem", null);
    __decorate([
        Action({ commit: 'show' })
    ], MensagemStore.prototype, "ocultarMensagem", null);
    MensagemStore = __decorate([
        Module({ dynamic: true, store: store, name: 'mensagem' })
    ], MensagemStore);
    return MensagemStore;
}(VuexModule));
export var MensagemStoreModule = getModule(MensagemStore, store);
