import { DTFORMAT } from '@/config/DateFormat';
import { DateTime } from 'luxon';
export var getFormats = {
    MILLS_TO_USUARIO_DATA: function (qual) {
        return DateTime.fromMillis(qual).toFormat(DTFORMAT.USUARIO_DATA);
    },
    ISO_TO_USUARIO_DATA: function (qual) {
        return DateTime.fromISO(qual).toFormat(DTFORMAT.USUARIO_DATA);
    },
    USUARIO_DATA_TO_ISO: function (qual) {
        return DateTime.fromFormat(qual, DTFORMAT.USUARIO_DATA).toISO();
    },
    ISO_TO_USUARIO_DATA_HORA: function (qual) {
        return DateTime.fromISO(qual).toFormat(DTFORMAT.USUARIO_DATETIME);
    },
    NUMERO_TO_DECIMAL: function (qual) {
        return qual.toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: 2 });
    }
};
