import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
var inputDate = /** @class */ (function (_super) {
    __extends(inputDate, _super);
    function inputDate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.date = '';
        _this.dateFormatted = '';
        _this.exibir = false;
        return _this;
    }
    inputDate.prototype.parseDate = function (data) {
        return data;
    };
    inputDate.prototype.formatarData = function () {
        this.dateFormatted = DateTime.fromISO(this.date).toFormat('dd/MM/yyyy');
        this.emitInputEvent();
    };
    inputDate.prototype.emitInputEvent = function () {
        this.$emit('input', DateTime.fromISO(this.date).toFormat(this.formato));
    };
    inputDate.prototype.mounted = function () {
        if (this.value) {
            this.date = DateTime.fromFormat(this.value, this.formato).toFormat('yyyy-MM-dd');
            this.dateFormatted = DateTime.fromISO(this.date).toFormat('dd/MM/yyyy');
        }
    };
    inputDate.prototype.clearItem = function () {
        this.date = '';
        this.dateFormatted = '';
        this.emitInputEvent();
    };
    __decorate([
        Prop({ default: 'Data' })
    ], inputDate.prototype, "label", void 0);
    __decorate([
        Prop({ default: '' })
    ], inputDate.prototype, "value", void 0);
    __decorate([
        Prop({ default: 'yyyy-MM-dd' })
    ], inputDate.prototype, "formato", void 0);
    inputDate = __decorate([
        Component
    ], inputDate);
    return inputDate;
}(Vue));
export default inputDate;
