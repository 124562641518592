import { __awaiter, __decorate, __extends, __generator } from "tslib";
import ClsApolloClient from '@/utils/ClsApolloClient';
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '..';
// @Module( { namespaced: true, name: 'login' } )
var NotificacaoStore = /** @class */ (function (_super) {
    __extends(NotificacaoStore, _super);
    function NotificacaoStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.novosLeads = [];
        return _this;
    }
    NotificacaoStore.prototype.notificacao = function (notificacao) {
        this.novosLeads = notificacao.novosLeads;
    };
    NotificacaoStore.prototype.refreshNotificacao = function (payload) {
        return __awaiter(this, void 0, Promise, function () {
            var clsApolloClient, status, rs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clsApolloClient = new ClsApolloClient();
                        status = "\n      getNovosLeads\n    ";
                        return [4 /*yield*/, clsApolloClient.query(payload, status, 'getNovosLeads')];
                    case 1:
                        rs = _a.sent();
                        return [2 /*return*/, {
                                novosLeads: rs
                            }];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], NotificacaoStore.prototype, "notificacao", null);
    __decorate([
        Action({ commit: 'notificacao', rawError: true })
    ], NotificacaoStore.prototype, "refreshNotificacao", null);
    NotificacaoStore = __decorate([
        Module({ dynamic: true, store: store, name: 'notificacao' })
    ], NotificacaoStore);
    return NotificacaoStore;
}(VuexModule));
export var NotificacaoStoreModule = getModule(NotificacaoStore, store);
