import { __decorate, __extends, __spreadArrays } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { clsUtils } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import ClienteCadastro from '@/views/clientes/ClienteCadastro.vue';
import { DataStoreModule } from '@/store/modules/DataStore';
var leadAtualizarClienteCls = /** @class */ (function (_super) {
    __extends(leadAtualizarClienteCls, _super);
    function leadAtualizarClienteCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.editedItem = [];
        _this.tmpRegistro = [];
        _this.status = StatusFormType.editando;
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    Object.defineProperty(leadAtualizarClienteCls.prototype, "DataStoreModule", {
        get: function () {
            return DataStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    leadAtualizarClienteCls.prototype.onChangeValue = function (newValue, oldValue) {
        this.getItems();
    };
    Object.defineProperty(leadAtualizarClienteCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    leadAtualizarClienteCls.prototype.getItems = function () {
        var _this = this;
        var getLeadById = "\n      getLeadById(idLead: " + this.idLead + ") {\n        leadUsuarios {\n          idUsuario\n        }\n      }    \n    ";
        this.clsApollo.query(this, getLeadById, 'getLeadById', 'Obtendo Consultores...').then(function (rs) {
            if (rs && rs.leadUsuarios && rs.leadUsuarios.length > 0) {
                _this.editedItem = rs.leadUsuarios.map(function (e) { return e.idUsuario; });
            }
            else {
                _this.editedItem = [];
            }
            _this.tmpRegistro = __spreadArrays(_this.editedItem);
        });
    };
    leadAtualizarClienteCls.prototype.btConfirmar = function () {
        var _this = this;
        var usuarios = [];
        this.editedItem.forEach(function (idUsuario) {
            usuarios.push({ idUsuario: idUsuario, idLead: _this.idLead });
        });
        var setLeadUsuario = "\n      setLeadUsuario(dados: " + clsUtils.ConverterEmGql(usuarios) + ", idLead: " + this.idLead + ") {\n        ok\n        mensagem\n      }\n    ";
        console.log('setLeadUsuario', setLeadUsuario);
        this.clsApollo.mutation(this, setLeadUsuario, 'setLeadUsuario', 'Atualizando Consultores...').then(function (rs) {
            if (rs.ok) {
                _this.tmpRegistro = __spreadArrays(_this.editedItem);
            }
        });
    };
    leadAtualizarClienteCls.prototype.btCancelar = function () {
        this.editedItem = __spreadArrays(this.tmpRegistro);
    };
    __decorate([
        Prop()
    ], leadAtualizarClienteCls.prototype, "idLead", void 0);
    __decorate([
        Watch('idLead')
    ], leadAtualizarClienteCls.prototype, "onChangeValue", null);
    leadAtualizarClienteCls = __decorate([
        Component({
            components: {
                ClienteCadastro: ClienteCadastro
            }
        })
    ], leadAtualizarClienteCls);
    return leadAtualizarClienteCls;
}(Vue));
export default leadAtualizarClienteCls;
