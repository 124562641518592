import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import inputDateRange from '@/components/inputDateRange.vue';
import { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { colors } from 'vuetify/lib';
import AgendaEvento from './AgendaEvento.vue';
import { LoginStoreModule } from '@/store/modules/LoginStore';
var CORES = [
    {
        idUsuario: 2,
        cor: colors.indigo.base
    },
    {
        idUsuario: 9,
        cor: colors.purple.base
    },
    {
        idUsuario: 11,
        cor: colors.orange.base
    },
    {
        idUsuario: 12,
        cor: colors.teal.base
    },
    {
        idUsuario: 13,
        cor: colors.pink.base
    },
    {
        idUsuario: 14,
        cor: colors.green.base
    },
    {
        idUsuario: 15,
        cor: colors.red.base
    }
];
var AgendaCls = /** @class */ (function (_super) {
    __extends(AgendaCls, _super);
    function AgendaCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.vendedores = [];
        _this.clsApollo = new ClsApolloClient();
        _this.events = [];
        _this.pesquisa = {};
        _this.value = '';
        _this.exibirEvento = false;
        _this.eventoSelecionado = {};
        return _this;
        /*
      
        private get DataStoreModule (): StateDataInterface { return DataStoreModule }
      
      
      
        private registros: Array<RelatorioProducaoInterface> = []
      
        private get getRoles (): typeof getRoles {
          return getRoles
        }
      
        private btPesquisar (): void {
          this.getRelatorio()
        }
      
        private clsApollo: ClsApolloClient = new ClsApolloClient()
      
        private cabecalhos = [
          { text: 'idUsuario', value: 'idUsuario' },
          { text: 'Novo', value: StatusRole.NOVO.toString() },
          { text: 'Tratativa', value: StatusRole.TRATATIVA.toString() },
          { text: 'Negociação', value: StatusRole.NEGOCIACAO.toString() },
          { text: 'Não Atende', value: StatusRole.NAO_ATENDE.toString() },
          { text: 'Incorreto', value: StatusRole.INCORRETO.toString() },
          { text: 'Sem Interesse', value: StatusRole.SEM_INTERESSE.toString() },
          { text: 'Futuro', value: StatusRole.CONTATO_FUTURO.toString() },
          { text: 'Matrícula', value: StatusRole.MATRICULA.toString() },
          { text: 'Total', value: 'Total' },
        ]
      
        private getRelatorio () {
      
          // console.log( this.getRoles.usuario( 1 ) )
      
          if ( this.pesquisa.periodoCadastro && this.pesquisa.periodoCadastro.length == 0 ) {
            delete this.pesquisa.periodoCadastro
          }
      
          let query: string = `
            relFunilPorVendedor(criterio: ${clsUtils.ConverterEmGql( clsUtils.removeNullFromObject( this.pesquisa ) )}) {
              idUsuario
              status
              qtd
            }
          `
      
          this.clsApollo.query<Array<RelatorioProducaoInterface>>( this, query, 'relFunilPorVendedor', 'Aguarde...' ).then( rs => {
            this.registros = this.totalizarRelatorio( rs )
          } ).catch( err => {
            console.log( 'erro: ', err )
          } )
        }
      
        private totalizarRelatorio ( rs: Array<RelatorioProducaoInterface> ): any {
          let obj: any = []
      
          rs.forEach( ( element: any ) => {
      
            let indice = _.findIndex( obj, { idUsuario: element.idUsuario } )
            if ( indice < 0 ) {
              obj.push( { idUsuario: element.idUsuario } )
              indice = obj.length - 1
              obj[indice]['Total'] = 0
            }
      
            obj[indice][element.status] = element.qtd
            obj[indice]['Total'] += element.qtd
      
          } );
      
          return obj
        }
      
        */
    }
    AgendaCls.prototype.getEventColor = function (evento) {
        return evento.color;
    };
    AgendaCls.prototype.getEvents = function () {
        /*
          atualizarDadosCliente(dados: ${clsUtils.ConverterEmGql( this.editedItem )}) {
            ok
            mensagem
          }
        */
        var _this = this;
        var atualizarDadosCliente = "\n      getAgenda (criterio: " + clsUtils.ConverterEmGql(clsUtils.removeNullFromObject(this.pesquisa)) + ") {\n        idLeadAgenda\n        idUsuario\n        nomeUsuario\n        canal\n        dataHora\n        cancelado\n        concluido\n        nomeCliente\n        idCliente\n        idLead\n        status\n      }    \n    ";
        this.clsApollo.query(this, atualizarDadosCliente, 'getAgenda', 'Pesquisando...').then(function (rs) {
            _this.exibirEvento = false;
            _this.events = [];
            rs.forEach(function (valor) {
                var indiceCor = _.findIndex(CORES, { idUsuario: valor.idUsuario });
                _this.events.push({
                    name: valor.nomeUsuario.concat(" - ").concat(valor.nomeCliente),
                    color: CORES[indiceCor >= 0 ? indiceCor : 0].cor,
                    start: DateTime.fromISO(valor.dataHora).toJSDate(),
                    end: DateTime.fromISO(valor.dataHora).toJSDate(),
                    timed: true,
                    idLeadAgenda: valor.idLeadAgenda,
                    idLead: valor.idLead,
                    statusAtual: valor.status,
                    idUsuario: valor.idUsuario
                });
            });
        });
    };
    AgendaCls.prototype.showEvent = function (evento) {
        if (LoginStoreModule.isAdmin || LoginStoreModule.isSupervisor || evento.event.idUsuario == LoginStoreModule.idUsuario) {
            this.eventoSelecionado = __assign({}, evento.event);
            this.exibirEvento = true;
        }
    };
    AgendaCls = __decorate([
        Component({
            components: {
                inputDateRange: inputDateRange,
                AgendaEvento: AgendaEvento
            }
        })
    ], AgendaCls);
    return AgendaCls;
}(Vue));
export default AgendaCls;
