import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ClsApolloClient from '@/utils/ClsApolloClient';
var IndicacaoViewCls = /** @class */ (function (_super) {
    __extends(IndicacaoViewCls, _super);
    function IndicacaoViewCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApollo = new ClsApolloClient();
        _this.registros = [];
        _this.editados = [];
        _this.pesquisa = {
            pesquisa: "",
            matricula: false,
            concluido: false
        };
        return _this;
        /*
        private validadoresFrontEnd = validadoresFrontEnd
        private formValid = false
      
        private editedItem: IndicacaoCadastroInterface = this.resetModel()
        private tmpRegistro: IndicacaoCadastroInterface = this.resetModel()
      
        private resetModel (): IndicacaoCadastroInterface {
          return {
            concluido: false,
            consultor: 0,
            dataNascimento: new Date(),
            foneContato: "",
            idIndicacao: 0,
            matricula: false,
            nomeResponsavel: "",
            observacao: ""
          }
        }
        private status: StatusFormType = StatusFormType.inicial
      
        private get StatusFormType (): any {
          return StatusFormType
        }
      
       private btCancelar (): void {
          this.status = StatusFormType.inicial
        }
      
        private btConfirmar (): void {
      
          if ( this.status == StatusFormType.editando ) {
      
            // @ts-ignore
            if ( this.$refs.form.validate() ) {
      
              let mutation: string = `
                  updateProduto(dados: ${clsUtils.ConverterEmGql( this.editedItem )} ) {
                    ok
                    mensagem
                  }
                `
      
              this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'updateProduto', 'Atualizando Produto' ).then( rs => {
                if ( rs.ok ) {
                  this.status = StatusFormType.inicial
                  this.getDetalhe()
                }
              } )
      
            }
          } else if ( this.status == StatusFormType.excluindo ) {
      
            let mutation: string = `
              delProduto(idProduto: ${this.editedItem.idProduto} ) {
                ok
                mensagem
              }
            `
      
            this.clsApollo.mutation<RespostaPadraoInterface>( this, mutation, 'delProduto', 'Excluindo Produto....' ).then( rs => {
              if ( rs.ok ) {
                this.status = StatusFormType.inicial
                this.getDetalhe()
              } else {
      
                MensagemStoreModule.exibirMensagem( {
                  mensagem: rs.mensagem,
                  tipo: TipoMensagemType.erro,
                  titulo: 'Erro!!!'
                } )
      
              }
            } )
      
          }
        }
      
       */
    }
    IndicacaoViewCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    IndicacaoViewCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n        getIndicacoes (pesquisa: \"" + this.pesquisa.pesquisa + "\", matricula: " + this.pesquisa.matricula + ", concluido: " + this.pesquisa.concluido + ") {\n        idIndicacao\n        consultor\n        nomeResponsavel\n        foneContato\n        observacao\n        dataNascimento\n        concluido\n        matricula\n        contatos {\n          contato\n          tipoContato\n        }\n        updatedAt\n      }\n    ";
        this.clsApollo.query(this, query, 'getIndicacoes', 'Pequisando Indicações...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    IndicacaoViewCls.prototype.alterarCadastro = function (indice) {
        if (!this.editados.includes(indice)) {
            this.editados.push(indice);
        }
    };
    IndicacaoViewCls.prototype.btSalvar = function (indice) {
        this.editados.splice(this.editados.indexOf(indice), 1);
    };
    IndicacaoViewCls = __decorate([
        Component
    ], IndicacaoViewCls);
    return IndicacaoViewCls;
}(Vue));
export default IndicacaoViewCls;
