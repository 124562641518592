import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import inputDateRange from '@/components/inputDateRange.vue';
import ClsApolloClient from '@/utils/ClsApolloClient';
var RelPrimeiraParcelaEmAtraso = /** @class */ (function (_super) {
    __extends(RelPrimeiraParcelaEmAtraso, _super);
    function RelPrimeiraParcelaEmAtraso() {
        /*
      
        private get DataStoreModule (): StateDataInterface { return DataStoreModule }
        
          private get getRoles (): typeof getRoles {
            return getRoles
          }
      
        */
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.matriculaValida = false;
        _this.pesquisa = {
            matricula: '',
            tipo: 'Remanejamento'
        };
        _this.nomeResponsavel = "";
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    RelPrimeiraParcelaEmAtraso.prototype.btPesquisar = function () {
        var _this = this;
        var query = "\n      responsavel(matricula: \"" + this.pesquisa.matricula + "\")\n    ";
        this.clsApollo.query(this, query, 'responsavel', 'Aguarde...').then(function (rs) {
            if (rs) {
                _this.nomeResponsavel = rs;
                _this.matriculaValida = true;
            }
            else {
                _this.nomeResponsavel = 'Matrícula Não Localizada';
                _this.matriculaValida = false;
            }
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    RelPrimeiraParcelaEmAtraso.prototype.btDownloadPDF = function () {
        var _this = this;
        var query = "\n      relTermo(matricula: \"" + this.pesquisa.matricula + "\", tipo: \"" + this.pesquisa.tipo + "\")\n    ";
        console.log(query);
        this.clsApollo.query(this, query, 'relTermo', 'Aguarde...').then(function (rs) {
            var link = document.createElement('a');
            link.href = 'data:application/octet-stream;base64,' + rs;
            link.download = "Termo de ".concat(_this.pesquisa.tipo).concat(' - ').concat(_this.nomeResponsavel).concat(".pdf");
            link.click();
        }).catch(function (err) {
            console.log('erro: ', err);
        });
        // let blob = new Blob( [rsEventoTOTVS], { type: 'application/txt' } )
        // let url = window.URL.createObjectURL( blob )
        /*
        // Download TXT
        const link = document.createElement( 'a' )
        link.setAttribute( 'href', 'data:text/plain;charset=utf-8,' + encodeURIComponent( this.csv ) );
        link.setAttribute( 'download', 'PrimeiraParcela.csv' );
        link.style.display = 'none'
        document.body.appendChild( link )
        link.click()
        document.body.removeChild( link )
        */
    };
    RelPrimeiraParcelaEmAtraso = __decorate([
        Component({
            components: {
                inputDateRange: inputDateRange
            },
            directives: {
                mask: mask
            }
        })
    ], RelPrimeiraParcelaEmAtraso);
    return RelPrimeiraParcelaEmAtraso;
}(Vue));
export default RelPrimeiraParcelaEmAtraso;
