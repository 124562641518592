import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { getFormats } from '@/utils/FormatarDados';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var OrigensCls = /** @class */ (function (_super) {
    __extends(OrigensCls, _super);
    function OrigensCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pesquisa = '';
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            // { text: 'idCliente', value: 'idCliente' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Custo', value: 'custo', aligh: 'right' },
            { text: 'Ativa', value: 'ativa' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    OrigensCls.prototype.resetModel = function () {
        return {
            idOrigem: 0,
            descricao: '',
            custo: 0,
            ativa: false
        };
    };
    Object.defineProperty(OrigensCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    OrigensCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    OrigensCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
    };
    OrigensCls.prototype.btIncluir = function () {
        this.status = StatusFormType.editando;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                idOrigem: 0,
                descricao: 'Teste de Origem',
                custo: 100,
                ativa: true
            };
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    OrigensCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n            updateOrigem(dados: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n              ok\n              mensagem\n            }\n          ";
                this.clsApollo.mutation(this, mutation, 'updateOrigem', 'Atualizando Origem').then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delOrigem(idOrigem: " + this.editedItem.idOrigem + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delOrigem', 'Excluindo Origem....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
                else {
                    MensagemStoreModule.exibirMensagem({
                        mensagem: rs.mensagem,
                        tipo: TipoMensagemType.erro,
                        titulo: 'Erro!!!'
                    });
                }
            });
        }
    };
    OrigensCls.prototype.viewItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.editando;
    };
    OrigensCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    OrigensCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n      getOrigens(pesquisa: \"" + this.pesquisa + "\") {\n        idOrigem\n        descricao\n        custo\n        ativa\n      }\n    ";
        this.clsApollo.query(this, query, 'getOrigens', 'Pequisando Origens...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    OrigensCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    Object.defineProperty(OrigensCls.prototype, "getFormats", {
        get: function () {
            return getFormats;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrigensCls.prototype, "podeExcluirOrigem", {
        get: function () {
            return LoginStoreModule.isSupervisor || LoginStoreModule.isAdmin;
        },
        enumerable: false,
        configurable: true
    });
    OrigensCls = __decorate([
        Component
    ], OrigensCls);
    return OrigensCls;
}(Vue));
export default OrigensCls;
