import { __decorate, __extends } from "tslib";
import { WaStatusType } from '@/interfaces/backend/WaInterfaces';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { Component, Vue } from 'vue-property-decorator';
import WhatsAppConversas from './WhatsAppConversas.vue';
import WhatsAppMensagens from './WhatsAppMensagens.vue';
var WhatsApp = /** @class */ (function (_super) {
    __extends(WhatsApp, _super);
    function WhatsApp() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.waStatus = -1;
        _this.waQRCode = "";
        return _this;
    }
    Object.defineProperty(WhatsApp.prototype, "WaStatusType", {
        get: function () {
            return WaStatusType;
        },
        enumerable: false,
        configurable: true
    });
    WhatsApp.prototype.mounted = function () {
        // this.startWhatsApp()
        this.getStatus();
    };
    WhatsApp.prototype.stopWhatsApp = function () {
        var _this = this;
        var clsApolloClient = new ClsApolloClient();
        var waFinalizar = "\n        wa_finalizar {\n          ok\n          mensagem\n        }\n    ";
        return clsApolloClient.mutation(this, waFinalizar, 'wa_finalizar').then(function (rsIniciar) {
            console.log(rsIniciar);
            return _this.getStatus().then(function (rs) {
                return rsIniciar;
            });
        });
    };
    WhatsApp.prototype.startWhatsApp = function () {
        var _this = this;
        this.waStatus = WaStatusType.inicializando;
        var clsApolloClient = new ClsApolloClient();
        var waIniciar = "\n        wa_iniciar {\n          ok\n          mensagem\n        }\n    ";
        return clsApolloClient.mutation(this, waIniciar, 'wa_iniciar').then(function (rsIniciar) {
            return _this.getStatus().then(function (rs) {
                return rsIniciar;
            });
        });
    };
    WhatsApp.prototype.refreshQRCode = function () {
        var _this = this;
        var clsApolloClient = new ClsApolloClient();
        var waQrCode = "\n        wa_qrCode \n    ";
        clsApolloClient.query(this, waQrCode, 'wa_qrCode').then(function (rs) {
            _this.waQRCode = "data:image/png;base64,".concat(rs);
        });
    };
    WhatsApp.prototype.getStatus = function () {
        var _this = this;
        var clsApolloClient = new ClsApolloClient();
        var getStatus = "\n        wa_status\n    ";
        return clsApolloClient.query(this, getStatus, 'wa_status').then(function (rs) {
            _this.waStatus = rs;
            if (_this.waStatus == WaStatusType.qrCodePendente) {
                _this.refreshQRCode();
            }
            return rs;
        }).catch(function () {
            _this.waStatus = WaStatusType.offLine;
            return WaStatusType.offLine;
        });
    };
    WhatsApp = __decorate([
        Component({
            components: {
                WhatsAppMensagens: WhatsAppMensagens,
                WhatsAppConversas: WhatsAppConversas
            }
        })
    ], WhatsApp);
    return WhatsApp;
}(Vue));
export default WhatsApp;
