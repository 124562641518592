import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import ClsUtils from 'zlib-utils';
var URL_WHATSAPP = 'https://wa.me/55';
var BtWhatsAppFree = /** @class */ (function (_super) {
    __extends(BtWhatsAppFree, _super);
    function BtWhatsAppFree() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsUtils = new ClsUtils();
        _this.txtWhatsAPP = '';
        return _this;
    }
    BtWhatsAppFree.prototype.abrirWhatsAPP = function () {
        window.open(URL_WHATSAPP.concat(this.clsUtils.getSomenteNumeros(this.txtWhatsAPP)));
        // window.open( 'https://web.whatsapp.com/send/?phone=55'.concat( this.clsUtils.getSomenteNumeros( this.txtWhatsAPP ) ).concat( '&text&type=phone_number&app_absent=0' ) )
        this.txtWhatsAPP = '';
    };
    BtWhatsAppFree = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], BtWhatsAppFree);
    return BtWhatsAppFree;
}(Vue));
export default BtWhatsAppFree;
