import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { clsUtils } from 'zlib-utils';
import { ClsReset } from '../ClsResets';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import ClienteCadastro from '@/views/clientes/ClienteCadastro.vue';
var LeadAtualizarClienteCls = /** @class */ (function (_super) {
    __extends(LeadAtualizarClienteCls, _super);
    function LeadAtualizarClienteCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.editedItem = (new ClsReset()).cliente;
        _this.tmpRegistro = __assign({}, _this.editedItem);
        _this.status = StatusFormType.editando;
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    LeadAtualizarClienteCls.prototype.onChangeValue = function (_newValue, _oldValue) {
        this.resetModel();
    };
    LeadAtualizarClienteCls.prototype.resetModel = function () {
        this.editedItem = __assign({}, this.value);
        this.tmpRegistro = __assign({}, this.editedItem);
    };
    Object.defineProperty(LeadAtualizarClienteCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    LeadAtualizarClienteCls.prototype.btConfirmar = function () {
        var _this = this;
        var atualizarDadosCliente = "\n      atualizarDadosCliente(dados: " + clsUtils.ConverterEmGql(this.editedItem) + ") {\n        ok\n        mensagem\n      }    \n    ";
        this.clsApollo.mutation(this, atualizarDadosCliente, 'atualizarDadosCliente', 'Atualizando...').then(function (rs) {
            if (rs.ok) {
                _this.$emit('refresh');
            }
        });
    };
    LeadAtualizarClienteCls.prototype.btCancelar = function () {
        this.resetModel();
    };
    __decorate([
        Prop()
    ], LeadAtualizarClienteCls.prototype, "value", void 0);
    __decorate([
        Watch('value')
    ], LeadAtualizarClienteCls.prototype, "onChangeValue", null);
    LeadAtualizarClienteCls = __decorate([
        Component({
            components: {
                ClienteCadastro: ClienteCadastro
            }
        })
    ], LeadAtualizarClienteCls);
    return LeadAtualizarClienteCls;
}(Vue));
export default LeadAtualizarClienteCls;
