import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
var inputDateRange = /** @class */ (function (_super) {
    __extends(inputDateRange, _super);
    function inputDateRange() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.date = [];
        _this.dateFormatted = '';
        _this.exibir = false;
        return _this;
    }
    inputDateRange.prototype.parseDate = function (data) {
        return data;
    };
    inputDateRange.prototype.formatarData = function () {
        this.dateFormatted = DateTime.fromISO(this.date[0]).toFormat('dd/MM/yyyy').concat('-').concat(DateTime.fromISO(this.date[1]).toFormat('dd/MM/yyyy'));
    };
    inputDateRange.prototype.emitInputEvent = function () {
        if (DateTime.fromISO(this.date[0]).isValid && DateTime.fromISO(this.date[1]).isValid) {
            if (this.date[0] > this.date[1]) {
                var tmp = this.date[1];
                this.date[1] = this.date[0];
                this.date[0] = tmp;
            }
            this.formatarData();
            this.$emit('input', [DateTime.fromISO(this.date[0]).toFormat(this.formato), DateTime.fromISO(this.date[1]).toFormat(this.formato)]);
            this.exibir = false;
        }
        else if (this.date.length == 0) {
            this.$emit('input', this.date);
        }
    };
    inputDateRange.prototype.mounted = function () {
        if (this.value && this.value.length >= 1 && DateTime.fromISO(this.date[0]).isValid) {
            this.date[0] = DateTime.fromFormat(this.value[0], this.formato).toFormat('yyyy-MM-dd');
        }
        if (this.value && this.value.length == 2 && DateTime.fromISO(this.date[1]).isValid) {
            this.date[1] = DateTime.fromFormat(this.value[1], this.formato).toFormat('yyyy-MM-dd');
        }
    };
    inputDateRange.prototype.clearItem = function () {
        this.date = [];
        this.dateFormatted = '';
        this.emitInputEvent();
    };
    __decorate([
        Prop({ default: 'Label do Campo....' })
    ], inputDateRange.prototype, "label", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], inputDateRange.prototype, "value", void 0);
    __decorate([
        Prop({ default: 'yyyy-MM-dd' })
    ], inputDateRange.prototype, "formato", void 0);
    inputDateRange = __decorate([
        Component
    ], inputDateRange);
    return inputDateRange;
}(Vue));
export default inputDateRange;
