import { CanalRole, StatusRole, TipoLeadRole } from "@/interfaces/backend/RolesInterfaces";
var ClsReset = /** @class */ (function () {
    function ClsReset() {
    }
    Object.defineProperty(ClsReset.prototype, "contatoInicial", {
        get: function () {
            return {
                idLead: 0,
                canal: CanalRole.TELEFONE,
                observacao: '',
                idOrigem: 0,
                tipo: TipoLeadRole.RECEPTIVO,
                idProduto: 0,
                status: StatusRole.NOVO
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClsReset.prototype, "cliente", {
        get: function () {
            return {
                idCliente: 0,
                nome: '',
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                uf: '',
                fone: '',
                foneOutros: '',
                whatsapp: '',
                email: '',
                twitter: '',
                facebook: '',
                instagram: '',
                linkedin: ''
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClsReset.prototype, "lead", {
        get: function () {
            return {
                idLead: 0,
                cliente: this.cliente,
                idCliente: 0,
                leadAgendas: [],
                leadUsuarios: [],
                idUsuarioCadastro: 0,
                usuarioCadastro: { idUsuario: 0, nome: '' },
                idProdutoInicial: 0,
                leadContatos: [],
                leadProdutos: [],
                origem: { idOrigem: 0, custo: 0, descricao: '', ativa: false },
                idOrigem: 0,
                canal: CanalRole.TELEFONE,
                tipo: TipoLeadRole.RECEPTIVO,
                statusAtual: StatusRole.NOVO,
                dataHora: new Date(),
                ativo: false
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClsReset.prototype, "novoAgendamento", {
        get: function () {
            return {
                canal: CanalRole.TELEFONE,
                dataHora: '',
                idLead: 0
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClsReset.prototype, "novoContato", {
        get: function () {
            return {
                canal: CanalRole.TELEFONE,
                idLead: 0,
                observacao: '',
                status: StatusRole.NOVO
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClsReset.prototype, "usuarioCadastro", {
        get: function () {
            return {
                idUsuario: 0,
                nome: ''
            };
        },
        enumerable: false,
        configurable: true
    });
    return ClsReset;
}());
export { ClsReset };
