import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getRoles } from '@/utils/dataTypes';
import { DataStoreModule } from '@/store/modules/DataStore';
import { getFormats } from '@/utils/FormatarDados';
var ClienteHistorico = /** @class */ (function (_super) {
    __extends(ClienteHistorico, _super);
    function ClienteHistorico() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ClienteHistorico.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteHistorico.prototype, "leadContatos", {
        get: function () {
            if (this.lead && this.lead.leadContatos) {
                return this.lead.leadContatos;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteHistorico.prototype, "getRoles", {
        get: function () {
            return getRoles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteHistorico.prototype, "getFormats", {
        get: function () {
            return getFormats;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { leadContatos: []; } })
    ], ClienteHistorico.prototype, "lead", void 0);
    ClienteHistorico = __decorate([
        Component
    ], ClienteHistorico);
    return ClienteHistorico;
}(Vue));
export default ClienteHistorico;
