import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import StoreModule from '@/store';
var Notificacao = /** @class */ (function (_super) {
    __extends(Notificacao, _super);
    function Notificacao() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Notificacao.prototype, "logado", {
        get: function () {
            return StoreModule.state.login.logado;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Notificacao.prototype, "notificacao", {
        get: function () {
            return StoreModule.state.notificacao;
        },
        enumerable: false,
        configurable: true
    });
    Notificacao.prototype.irPara = function (onde) {
        this.$router.push(onde);
    };
    Object.defineProperty(Notificacao.prototype, "notificacoes", {
        get: function () {
            var retorno = [];
            this.notificacao.novosLeads.forEach(function (nome) {
                retorno.push({
                    titulo: 'Novo Lead',
                    descricao: nome,
                    link: '/Leads',
                    icone: 'mdi-account-details',
                    exibir: true
                });
            });
            return retorno;
        },
        enumerable: false,
        configurable: true
    });
    Notificacao = __decorate([
        Component
    ], Notificacao);
    return Notificacao;
}(Vue));
export default Notificacao;
