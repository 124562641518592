import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField
Vue.component('v-text-field', VTextField);
import { createProvider } from './vue-apollo';
Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
});
// Vue.component( 'VCurrencyField', VCurrencyField )
Vue.config.productionTip = false;
new Vue({
    router: router,
    store: store,
    vuetify: vuetify,
    apolloProvider: createProvider(),
    render: function (h) { return h(App); }
}).$mount('#app');
