import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { getRoles, StatusFormType } from '@/utils/dataTypes';
import { getFormats } from '@/utils/FormatarDados';
import ClienteHistorico from '@/views/clientes/ClienteHistorico.vue';
import ClienteAgendamentos from '@/views/clientes/ClienteAgendamentos.vue';
import leadsPesquisa from './LeadsPesquisa.vue';
import LeadNovoAcompanhamento from './LeadNovoAcompanhamento.vue';
import LeadNovoAgendamento from './LeadNovoAgendamento.vue';
import LeadAtualizarCliente from './LeadAtualizarCliente.vue';
import LeadAtualizarConsultor from './LeadAtualizarConsultor.vue';
import _ from 'lodash';
import { ClsReset } from '../ClsResets';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
import { StatusRole } from '@/interfaces/backend/RolesInterfaces';
var LeadsCls = /** @class */ (function (_super) {
    __extends(LeadsCls, _super);
    function LeadsCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pesquisa = {
            ativo: true
        };
        _this.editedItem = (new ClsReset()).lead;
        _this.tmpRegistro = __assign({}, _this.editedItem);
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'Cliente', value: 'nomeCliente' },
            { text: 'Fones', value: 'telefonesCliente' },
            { text: 'Agenda em', value: 'agenda' },
            { text: 'Último Contato', value: 'ultimoContato' },
            { text: 'Consultor', value: 'usuarioNome' },
            { text: 'Usuário Cadastro', value: 'usuarioCadastroNome' },
            { text: 'Origem', value: 'origem' },
            { text: 'Status', value: 'statusAtual' },
            { text: 'Canal', value: 'canal' },
            { text: 'Tipo', value: 'tipo' },
            { text: 'Situação', value: 'ativo' },
            { text: 'Cadastrado em', value: 'dataHora' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        // Prevenção de Clique acidental
        _this.contadorClickExcluirLead = 0;
        return _this;
    }
    Object.defineProperty(LeadsCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    LeadsCls.prototype.getDetalhe = function () {
        var _this = this;
        this.pesquisa = _.pickBy(this.pesquisa, function (v) { return v !== undefined; });
        if (this.pesquisa.periodoAgendamento && this.pesquisa.periodoAgendamento.length == 0) {
            delete this.pesquisa.periodoAgendamento;
        }
        if (this.pesquisa.periodoCadastro && this.pesquisa.periodoCadastro.length == 0) {
            delete this.pesquisa.periodoCadastro;
        }
        var query = "\n      getLeads(pesquisa: " + clsUtils.ConverterEmGql(clsUtils.removeNullFromObject(this.pesquisa)) + ") {\n        idLead\n        canal\n        tipo\n        statusAtual\n        dataHora\n        ativo\n        idCliente\n        nomeCliente\n        telefonesCliente\n        usuarioNome\n        usuarioCadastroNome\n        origem\n        ultimoContato\n        agendaCanal\n        agendaDataHora\n        agendaUsuarioNome\n      }\n    ";
        console.log(query);
        this.clsApollo.query(this, query, 'getLeads', 'Pequisando Leads...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    LeadsCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    LeadsCls.prototype.getLeadById = function (idLead) {
        var query = "\n      getLeadById(idLead: " + idLead + ") {\n        idLead\n        idProdutoInicial\n        canal\n        statusAtual\n        dataHora\n        tipo\n        ativo\n        leadUsuarios {\n          usuario {\n            nome\n          }\n        }\n        leadAgendas {\n          idUsuario\n          dataHora\n          canal\n          concluido\n          cancelado\n          usuario {\n            idUsuario\n            nome\n          }\n        }\n        cliente {\n          idCliente\n          nome\n          cep\n          endereco\n          bairro\n          cidade\n          uf\n          fone\n          foneOutros\n          whatsapp\n          email\n          twitter\n          facebook\n          instagram\n          linkedin\n        }\n        usuarioCadastro {\n          idUsuario\n          nome\n        }\n        origem {\n          descricao\n        }\n        leadContatos {\n          canal\n          status\n          observacao\n          dataHora\n          usuario {\n            nome\n          }\n        }\n      }\n    ";
        return this.clsApollo.query(this, query, 'getLeadById', 'Pequisando Lead...').then(function (rs) {
            return rs;
        }).catch(function (err) {
            throw err;
        });
    };
    LeadsCls.prototype.btEditarLead = function (idLead) {
        var _this = this;
        this.getLeadById(idLead).then(function (rs) {
            _this.editedItem = __assign({}, rs);
            _this.status = StatusFormType.editando;
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({
                mensagem: 'Erro ao Pesquisar Lead!',
                tipo: TipoMensagemType.erro,
                titulo: 'Erro!!!'
            });
        });
    };
    LeadsCls.prototype.btExcluirLead = function (idLead) {
        var _this = this;
        if (++this.contadorClickExcluirLead >= 2) {
            this.contadorClickExcluirLead = 0;
            this.getLeadById(idLead).then(function (rs) {
                var excluirLead = "\n      excluirLead(idLead: " + idLead + ") {\n        ok\n        mensagem\n      }    \n    ";
                _this.clsApollo.mutation(_this, excluirLead, 'excluirLead', 'Excluindo...').then(function (rs) {
                    if (rs.ok) {
                        _this.btCancelar();
                    }
                });
            }).catch(function () {
                MensagemStoreModule.exibirMensagem({
                    mensagem: 'Erro ao Pesquisar Lead!',
                    tipo: TipoMensagemType.erro,
                    titulo: 'Erro!!!'
                });
            });
        }
    };
    Object.defineProperty(LeadsCls.prototype, "getFormats", {
        get: function () {
            return getFormats;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LeadsCls.prototype, "getRoles", {
        get: function () {
            return getRoles;
        },
        enumerable: false,
        configurable: true
    });
    LeadsCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
        this.btPesquisar();
    };
    LeadsCls.prototype.refreshLead = function () {
        console.log('Refresh Lead....');
    };
    Object.defineProperty(LeadsCls.prototype, "podeAlterarConsultores", {
        get: function () {
            return LoginStoreModule.isAdmin || LoginStoreModule.isSupervisor;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LeadsCls.prototype, "podeApagarLead", {
        get: function () {
            return (LoginStoreModule.isAdmin || LoginStoreModule.isSupervisor)
                && this.status == StatusFormType.editando
                && (this.editedItem.statusAtual == StatusRole.NOVO || this.editedItem.statusAtual == StatusRole.TRATATIVA);
        },
        enumerable: false,
        configurable: true
    });
    LeadsCls = __decorate([
        Component({
            components: {
                leadsPesquisa: leadsPesquisa,
                ClienteHistorico: ClienteHistorico,
                LeadNovoAcompanhamento: LeadNovoAcompanhamento,
                LeadNovoAgendamento: LeadNovoAgendamento,
                LeadAtualizarCliente: LeadAtualizarCliente,
                ClienteAgendamentos: ClienteAgendamentos,
                LeadAtualizarConsultor: LeadAtualizarConsultor
            }
        })
    ], LeadsCls);
    return LeadsCls;
}(Vue));
export default LeadsCls;
