import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
var IndicacaoObrigadoCls = /** @class */ (function (_super) {
    __extends(IndicacaoObrigadoCls, _super);
    function IndicacaoObrigadoCls() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IndicacaoObrigadoCls.prototype.btToFleekSite = function () {
        window.location.assign("https://www.fleekcursos.com.br");
    };
    __decorate([
        Prop()
    ], IndicacaoObrigadoCls.prototype, "ok", void 0);
    IndicacaoObrigadoCls = __decorate([
        Component
    ], IndicacaoObrigadoCls);
    return IndicacaoObrigadoCls;
}(Vue));
export default IndicacaoObrigadoCls;
