import Vue from 'vue';
import VueRouter from 'vue-router';
import Agenda from '../views/agenda/Agenda.vue';
import AlterarSenha from '../views/usuarios/AlterarSenha.vue';
import BtWhatsAppFree from '../views/whatsapp/BtWhatsAppFree.vue';
import Clientes from '../views/clientes/Clientes.vue';
import Indicacao from '../views/indicacoes/indicacao.vue';
import IndicacaoObrigado from '../views/indicacoes/indicacaoObrigado.vue';
import IndicacaoView from '../views/indicacoes/indicacaoView.vue';
import Leads from '../views/leads/Leads.vue';
import Login from '../views/login/Login.vue';
import Logout from '../views/login/Logout.vue';
import MenuInicial from '../layout/MenuInicial.vue';
import Origens from '../views/origens/Origens.vue';
import Produtos from '../views/produtos/Produtos.vue';
import RelFunil from '../views/relatorios/funil/RelFunil.vue';
import RelProdutividade from '../views/relatorios/produtividade/RelProdutividade.vue';
import RelPrimeiraParcelaEmAtraso from '../views/financeiro/relatorios/RelPrimeiraParcelaEmAtraso.vue';
import RelTermos from '../views/financeiro/relatorios/RelTermos.vue';
import RelProcessosAdmPendentes from '../views/financeiro/relatorios/RelProcessosAdmPendentes.vue';
import WhatsApp from '../views/whatsapp/WhatsApp.vue';
import Teste from '../testes_apagar/Teste.vue';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { LoginStoreModule } from '@/store/modules/LoginStore';
Vue.use(VueRouter);
var rotasLivresLogin = ['Login', 'Testes', 'BtWhatsAppFree', 'Indicacao', 'IndicacaoObrigado'];
var routesTeste = EMDESENVOLVIMENTO ? [
    {
        path: '/Testes',
        name: 'Testes',
        component: Teste
    }
] : [];
var routesGeral = [
    {
        path: '/Agenda',
        name: 'Agenda',
        component: Agenda
    },
    {
        path: '/AlterarSenha',
        name: 'AlterarSenha',
        component: AlterarSenha
    },
    {
        path: '/BtWhatsAppFree',
        name: 'BtWhatsAppFree',
        component: BtWhatsAppFree
    },
    {
        path: '/Clientes',
        name: 'Clientes',
        component: Clientes
    },
    {
        path: '/Indicacao',
        name: 'Indicacao',
        component: Indicacao
    },
    {
        path: '/IndicacaoObrigado/:ok',
        name: 'IndicacaoObrigado',
        component: IndicacaoObrigado,
        props: true
    },
    {
        path: '/IndicacaoView',
        name: 'IndicacaoView',
        component: IndicacaoView,
    },
    {
        path: '/Leads',
        name: 'Leads',
        component: Leads
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/',
        name: 'MenuInicial',
        component: MenuInicial
    },
    {
        path: '/Origens',
        name: 'Origens',
        component: Origens
    },
    {
        path: '/Produtos',
        name: 'Produtos',
        component: Produtos
    },
    {
        path: '/RelFunil',
        name: 'RelFunil',
        component: RelFunil
    },
    {
        path: '/RelProcessosAdmPendentes',
        name: 'RelProcessosAdmPendentes',
        component: RelProcessosAdmPendentes
    },
    {
        path: '/RelProdutividade',
        name: 'RelProdutividade',
        component: RelProdutividade
    },
    {
        path: '/RelPrimeiraParcelaEmAtraso',
        name: 'RelPrimeiraParcelaEmAtraso',
        component: RelPrimeiraParcelaEmAtraso
    },
    {
        path: '/RelTermos',
        name: 'RelTermos',
        component: RelTermos
    },
    {
        path: '/WhatsApp',
        name: 'WhatsApp',
        component: WhatsApp
    }
    /*,
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ // '../views/About.vue')
    // }
];
var router = new VueRouter({
    routes: routesGeral.concat(routesTeste)
});
// let contador: number = 0
router.beforeResolve(function (to, from, next) {
    /*
    contador++
  
    console.log( "=================================" )
    console.log( 'CONTADOR: ', contador )
  
    if ( !LoginStoreModule.logado )
      console.log( 'Dentro do Before Resolve....', 'LoginStoreModule.logado', LoginStoreModule.logado, 'to', to, 'from', from )
  */
    if (rotasLivresLogin.includes(to.name ? to.name : '') || LoginStoreModule.logado || to.name == "Login") {
        next(); //proceed to the route
    }
    else {
        //   console.log( "Entrei dentro do else...." )
        if (from.path != '/Login' || to.path != '/Login') {
            //    console.log("Redirecionando /LOGIN....")
            next({ replace: true, path: '/Login' });
        }
        else {
            // console.log("Redirecionando para Next....")
            next();
        }
    }
});
export default router;
