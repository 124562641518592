/*

Máximo de Tamanho:

{ id: 67,
  type: 21,
  subtype: 32,
  t: 1611738497,
  notifyName: 0,
  from: 28,
  to: 28,
  self: 2,
  ack: 4,
  invis: 0,
  star: 0,
  isFromTemplate: 0,
  mentionedJidList: 0,
  isVcardOverMmsDocument: 0,
  templateParams: 0,
  isForwarded: 0,
  labels: 0,
  chatId: 0,
  fromMe: 0,
  sender: 0,
  timestamp: 1611738497,
  isGroupMsg: 0,
  isMedia: 0,
  isNotification: 0,
  isPSA: 0,
  chat: 0,
  isOnline: 0,
  lastSeen: 0,
  quotedMsgObj: 0,
  mediaData: 0,
  reply: 0,
  body: 95568,
  broadcast: 0,
  ephemeralOutOfSync: 0,
  content: 95568,
  thumbnail: 10176,
  richPreviewType: 1,
  quotedMsg: 0,
  quotedStanzaID: 32,
  quotedParticipant: 18,
  clientUrl: 77,
  directPath: 133,
  mimetype: 71,
  duration: 2,
  filehash: 44,
  uploadhash: 44,
  size: 14010998,
  mediaKey: 44,
  mediaKeyTimestamp: 1611654707,
  matchedText: 89,
  forwardingScore: 3,
  canonicalUrl: 119,
  title: 166,
  description: 186,
  interactiveAnnotations: 0,
  isViewOnce: 0,
  width: 1920,
  height: 1560,
  scanLengths: 0,
  caption: 96,
  filename: 58,
  pageCount: 3,
  isAnimated: 0,
  vcardFormattedName: 21,
  isGif: 0,
  gifAttribution: 0,
  firstFrameLength: 28194,
  firstFrameSidecar: 0,
  author: 17,
  recipients: 0 }

{ id: 'false_553788118785@c.us_9AFC4B21ED0738A3D4F4B5D39D96642B',
  body: 'Kkkk',
  type: 'chat',
  t: 1611653977,
  notifyName: '',
  from: '553788118785@c.us',
  to: '553732129399@c.us',
  self: 'in',
  ack: -1,
  invis: false,
  isNewMsg: true,
  star: false,
  recvFresh: true,
  isFromTemplate: false,
  broadcast: false,
  mentionedJidList: [],
  isVcardOverMmsDocument: false,
  isForwarded: false,
  labels: [],
  ephemeralOutOfSync: false,
  chatId: '553788118785@c.us',
  fromMe: false,
  sender:
   { id: '553788118785@c.us',
     name: 'Zanatta',
     shortName: 'Zanatta',
     pushname: 'Zanatta',
     type: 'in',
     isBusiness: false,
     isEnterprise: false,
     statusMute: false,
     labels: [],
     formattedName: 'Zanatta',
     isMe: false,
     isMyContact: true,
     isPSA: false,
     isUser: true,
     isWAContact: true,
     profilePicThumbObj: {},
     msgs: null },
  timestamp: 1611653977,
  content: 'Kkkk',
  isGroupMsg: false,
  isMedia: false,
  isNotification: false,
  isPSA: false,
  chat:
   { id: '553788118785@c.us',
     pendingMsgs: false,
     lastReceivedKey:
      { fromMe: false,
        remote: '553788118785@c.us',
        id: '8B077680C9126C20028FC2FD96846A12',
        _serialized: 'false_553788118785@c.us_8B077680C9126C20028FC2FD96846A12' },
     t: 1611653930,
     unreadCount: 7,
     archive: false,
     isReadOnly: false,
     modifyTag: 303890,
     muteExpiration: 0,
     name: 'Zanatta',
     notSpam: true,
     pin: 0,
     ephemeralDuration: 0,
     ephemeralSettingTimestamp: 0,
     msgs: null,
     kind: 'chat',
     isGroup: false,
     contact:
      { id: '553788118785@c.us',
        name: 'Zanatta',
        shortName: 'Zanatta',
        pushname: 'Zanatta',
        type: 'in',
        isBusiness: false,
        isEnterprise: false,
        statusMute: false,
        labels: [],
        formattedName: 'Zanatta',
        isMe: false,
        isMyContact: true,
        isPSA: false,
        isUser: true,
        isWAContact: true,
        profilePicThumbObj: {},
        msgs: null },
     groupMetadata: null,
     presence: { id: '553788118785@c.us', chatstates: [] },
     isOnline: true,
     lastSeen: null },
  isOnline: true,
  lastSeen: null,
  quotedMsgObj: null,
  mediaData: {} }


*/
export var WAMessageTypeType;
(function (WAMessageTypeType) {
    WAMessageTypeType["e2e_notification"] = "e2e_notification";
    WAMessageTypeType["chat"] = "chat";
})(WAMessageTypeType || (WAMessageTypeType = {}));
export var WaStatusType;
(function (WaStatusType) {
    WaStatusType[WaStatusType["qrCodePendente"] = 1] = "qrCodePendente";
    WaStatusType[WaStatusType["onLine"] = 2] = "onLine";
    WaStatusType[WaStatusType["offLine"] = 3] = "offLine";
    WaStatusType[WaStatusType["inicializando"] = 4] = "inicializando";
    WaStatusType[WaStatusType["erro"] = 5] = "erro";
})(WaStatusType || (WaStatusType = {}));
