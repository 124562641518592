import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { getFormats } from '@/utils/FormatarDados';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var ProdutosCls = /** @class */ (function (_super) {
    __extends(ProdutosCls, _super);
    function ProdutosCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pesquisa = '';
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            // { text: 'idCliente', value: 'idCliente' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Valor', value: 'valor', aligh: 'right' },
            { text: 'Ativo', value: 'ativo' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    ProdutosCls.prototype.resetModel = function () {
        return {
            idProduto: 0,
            descricao: '',
            valor: 0,
            ativo: false,
            chavelead: ''
        };
    };
    Object.defineProperty(ProdutosCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    ProdutosCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    ProdutosCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
    };
    ProdutosCls.prototype.btIncluir = function () {
        this.status = StatusFormType.editando;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                idProduto: 0,
                descricao: 'Teste de Produto',
                valor: 100,
                ativo: true,
                chavelead: 'ChaveLead'
            };
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    ProdutosCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n            updateProduto(dados: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n              ok\n              mensagem\n            }\n          ";
                this.clsApollo.mutation(this, mutation, 'updateProduto', 'Atualizando Produto').then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delProduto(idProduto: " + this.editedItem.idProduto + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delProduto', 'Excluindo Produto....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
                else {
                    MensagemStoreModule.exibirMensagem({
                        mensagem: rs.mensagem,
                        tipo: TipoMensagemType.erro,
                        titulo: 'Erro!!!'
                    });
                }
            });
        }
    };
    ProdutosCls.prototype.viewItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.editando;
    };
    ProdutosCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    ProdutosCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n      getProdutos(pesquisa: \"" + this.pesquisa + "\") {\n        idProduto\n        descricao\n        valor\n        ativo\n      }\n    ";
        this.clsApollo.query(this, query, 'getProdutos', 'Pequisando Produtos...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    ProdutosCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    Object.defineProperty(ProdutosCls.prototype, "getFormats", {
        get: function () {
            return getFormats;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProdutosCls.prototype, "podeExcluirProduto", {
        get: function () {
            return LoginStoreModule.isSupervisor || LoginStoreModule.isAdmin;
        },
        enumerable: false,
        configurable: true
    });
    ProdutosCls = __decorate([
        Component
    ], ProdutosCls);
    return ProdutosCls;
}(Vue));
export default ProdutosCls;
