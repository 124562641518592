import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var Mensagem = /** @class */ (function (_super) {
    __extends(Mensagem, _super);
    function Mensagem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Mensagem.prototype, "MensagemStoreModule", {
        get: function () { return MensagemStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Mensagem.prototype, "TipoMensagemType", {
        get: function () { return TipoMensagemType; },
        enumerable: false,
        configurable: true
    });
    Mensagem = __decorate([
        Component
    ], Mensagem);
    return Mensagem;
}(Vue));
export default Mensagem;
