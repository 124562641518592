import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ClienteHistorico from './ClienteHistorico.vue';
import ClienteAgendamentos from './ClienteAgendamentos.vue';
import ClienteCadastro from './ClienteCadastro.vue';
import { mask } from 'vue-the-mask';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { ClienteUpdateRole, StatusTiposUsuariosPermitidoAlterarStatusNovoCliente } from '@/interfaces/backend/RolesInterfaces';
import { DataStoreModule } from '@/store/modules/DataStore';
import { ClsReset } from '../ClsResets';
import { StatusRole, StatusPermitidoVendedorNovosClientes } from '@/interfaces/backend/RolesInterfaces';
var ClientesCls = /** @class */ (function (_super) {
    __extends(ClientesCls, _super);
    function ClientesCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clienteUpdateRole = ClienteUpdateRole.NOVO;
        _this.pesquisa = {
            fone: '',
            texto: '',
            foneParcial: ''
        };
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.leadContatoInicial = _this.resetLeadContatoInicial();
        _this.tmpLeadContatoInicial = _this.resetLeadContatoInicial();
        _this.leadAberta = _this.resetLeadAberta();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            // { text: 'idCliente', value: 'idCliente' },
            { text: 'Nome', value: 'nome' },
            { text: 'Fone', value: 'fone' },
            { text: 'Fone Outros', value: 'foneOutros' },
            { text: 'WhatsApp', value: 'whatsapp' },
            { text: 'e-mail', value: 'email' },
            /*    { text: 'Twitter', value: 'twitter' },
                { text: 'Facebook', value: 'facebook' },
                { text: 'Instagram', value: 'instagram' },
                { text: 'Linkedin', value: 'linkedin' },
                { text: 'Endereco', value: 'endereco' },
                { text: 'Cep', value: 'cep' },
                */
            { text: 'Bairro', value: 'bairro' },
            { text: 'Cidade', value: 'cidade' },
            { text: 'UF', value: 'uf' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    Object.defineProperty(ClientesCls.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "ClienteUpdateRole", {
        get: function () { return ClienteUpdateRole; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    ClientesCls.prototype.resetLeadAberta = function () {
        return new ClsReset().lead;
    };
    ClientesCls.prototype.resetLeadContatoInicial = function () {
        return new ClsReset().contatoInicial;
    };
    ClientesCls.prototype.resetModel = function () {
        return new ClsReset().cliente;
    };
    Object.defineProperty(ClientesCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.editando ? "Editar" : this.status == StatusFormType.visualizando ? "Exibir" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    ClientesCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    ClientesCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
        this.clienteUpdateRole = ClienteUpdateRole.NOVO;
    };
    ClientesCls.prototype.btIncluir = function () {
        this.clienteUpdateRole = ClienteUpdateRole.NOVO;
        this.status = StatusFormType.editando;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                idCliente: 0,
                nome: 'Marcelo João Zanatta',
                cep: '35500010',
                endereco: 'R. Sacramento, 23',
                bairro: 'Bom Pastor',
                cidade: 'Divinopolis',
                uf: 'MG',
                fone: '37988118785',
                foneOutros: '',
                whatsapp: '37988118785',
                email: 'zanatta@fleekcursos.com.br',
                twitter: '@twitterzanatta',
                facebook: 'zanattapeople facebook',
                instagram: 'zanatta instagram',
                linkedin: 'zanatta linkedin'
            };
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    ClientesCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = '';
                var nomeMutation = '';
                var mensagem = '';
                // Novo Cliente
                if (this.clienteUpdateRole == ClienteUpdateRole.NOVO) {
                    mensagem = 'Incluindo Cliente....';
                    nomeMutation = 'novoCliente';
                    mutation = "\n          novoCliente(dados: " + clsUtils.ConverterEmGql(this.editedItem) + ", lead: " + clsUtils.ConverterEmGql(this.leadContatoInicial) + " ) {\n            ok\n            mensagem\n          }\n          ";
                }
                else {
                    mensagem = 'Atualizando Cliente....';
                    nomeMutation = 'updateCliente';
                    mutation = "\n            updateCliente(clienteUpdateRole: " + this.clienteUpdateRole + ", dados: " + clsUtils.ConverterEmGql(this.editedItem) + ", lead: " + clsUtils.ConverterEmGql(this.leadContatoInicial) + " ) {\n              ok\n              mensagem\n            }\n          ";
                }
                this.clsApollo.mutation(this, mutation, nomeMutation, mensagem).then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delCliente(idCliente: " + this.editedItem.idCliente + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delCliente', 'Excluindo Cliente....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    ClientesCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    ClientesCls.prototype.viewItem = function (item) {
        var _this = this;
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.leadContatoInicial = this.resetLeadContatoInicial();
        this.tmpLeadContatoInicial = this.resetLeadContatoInicial();
        var query = "\n      getLeadAberta(idCliente: " + this.editedItem.idCliente + ") {\n        idLead\n        idProdutoInicial\n        canal\n        statusAtual\n        dataHora\n        tipo\n        usuarioCadastro {\n          idUsuario\n          nome\n        }\n        origem {\n          descricao\n        }\n        leadAgendas {\n          idUsuario\n          dataHora\n          canal\n          usuario {\n            idUsuario\n            nome\n          }\n        }\n        leadContatos {\n          canal\n          status\n          observacao\n          dataHora\n          usuario {\n            nome\n          }\n        }\n      }\n    ";
        this.clsApollo.query(this, query, 'getLeadAberta', 'Pequisando Leads...').then(function (rs) {
            if (rs && rs.idLead) {
                _this.clienteUpdateRole = ClienteUpdateRole.EXISTENTE_COM_LEAD;
                _this.leadContatoInicial.idLead = rs.idLead;
                _this.leadContatoInicial.status = rs.statusAtual;
                _this.tmpLeadContatoInicial = __assign({}, _this.leadContatoInicial);
                _this.leadAberta = rs;
            }
            else {
                _this.clienteUpdateRole = ClienteUpdateRole.EXISTENTE_SEM_LEAD;
                _this.leadAberta = _this.resetLeadAberta();
            }
            _this.status = StatusFormType.editando;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    ClientesCls.prototype.getDetalhe = function () {
        var _this = this;
        var fone = this.pesquisa.fone && this.pesquisa.fone.length > 0 ? this.pesquisa.fone : this.pesquisa.foneParcial;
        var query = "\n      getClientes(fone: \"" + fone + "\", texto: \"" + this.pesquisa.texto + "\") {\n        idCliente\n        nome\n        cep\n        endereco\n        bairro\n        cidade\n        uf\n        fone\n        foneOutros\n        whatsapp\n        email\n        twitter\n        facebook\n        instagram\n        linkedin\n      }\n    ";
        this.clsApollo.query(this, query, 'getClientes', 'Pequisando Clientes...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    ClientesCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    Object.defineProperty(ClientesCls.prototype, "permitirEdicaoCampos", {
        get: function () {
            return this.status == StatusFormType.editando || this.status == StatusFormType.incluindo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "podeExcluirCliente", {
        get: function () {
            return LoginStoreModule.isSupervisor || LoginStoreModule.isAdmin;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "podeAlterarStatus", {
        get: function () {
            return StatusTiposUsuariosPermitidoAlterarStatusNovoCliente.includes(LoginStoreModule.tipo);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientesCls.prototype, "statusPermitidosNovosClientes", {
        get: function () {
            var _this = this;
            return DataStoreModule.status.filter(function (valor) {
                return valor.idStatus == StatusRole.NOVO || _this.podeAlterarStatus && StatusPermitidoVendedorNovosClientes.includes(valor.idStatus);
            });
        },
        enumerable: false,
        configurable: true
    });
    ClientesCls.prototype.inputFone = function () {
        this.pesquisa.foneParcial = '';
    };
    ClientesCls.prototype.inputFoneParcial = function () {
        this.pesquisa.fone = '';
    };
    ClientesCls = __decorate([
        Component({
            components: {
                ClienteHistorico: ClienteHistorico,
                ClienteCadastro: ClienteCadastro,
                ClienteAgendamentos: ClienteAgendamentos
            },
            directives: {
                mask: mask
            }
        })
    ], ClientesCls);
    return ClientesCls;
}(Vue));
export default ClientesCls;
