import { CanalRoleDescricao, StatusRoleDescricao, TipoLeadRoleDescricao } from '@/interfaces/backend/RolesInterfaces';
import { DataStoreModule } from '@/store/modules/DataStore';
import _ from 'lodash';
export var StatusFormType;
(function (StatusFormType) {
    StatusFormType[StatusFormType["inicial"] = 0] = "inicial";
    StatusFormType[StatusFormType["valido"] = 1] = "valido";
    StatusFormType[StatusFormType["erro"] = 2] = "erro";
    StatusFormType[StatusFormType["processando"] = 3] = "processando";
    StatusFormType[StatusFormType["editando"] = 4] = "editando";
    StatusFormType[StatusFormType["incluindo"] = 5] = "incluindo";
    StatusFormType[StatusFormType["excluindo"] = 6] = "excluindo";
    StatusFormType[StatusFormType["visualizando"] = 7] = "visualizando";
})(StatusFormType || (StatusFormType = {}));
export var getRoles = {
    status: function (idStatus) {
        return _.find(StatusRoleDescricao, { idStatus: idStatus });
    },
    canal: function (idCanal) {
        return _.find(CanalRoleDescricao, { idCanal: idCanal });
    },
    tipo: function (idTipo) {
        return _.find(TipoLeadRoleDescricao, { idTipo: idTipo });
    },
    usuario: function (idUsuario) {
        return _.find(DataStoreModule.usuarios, { idUsuario: idUsuario });
    }
};
