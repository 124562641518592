import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { CanalRoleDescricao, StatusRoleDescricao, TipoLeadRoleDescricao } from '@/interfaces/backend/RolesInterfaces';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '..';
import { LoginStoreModule } from './LoginStore';
var DataStore = /** @class */ (function (_super) {
    __extends(DataStore, _super);
    function DataStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.produtos = [];
        _this.canais = CanalRoleDescricao;
        _this.origens = [];
        _this.tiposLead = TipoLeadRoleDescricao;
        _this.status = StatusRoleDescricao;
        _this.usuarios = [];
        return _this;
    }
    DataStore.prototype.updateOrigemMutation = function (origens) {
        this.origens = origens;
    };
    DataStore.prototype.updateProdutoMutation = function (produtos) {
        this.produtos = produtos;
    };
    DataStore.prototype.updateUsuarioMutation = function (usuarios) {
        this.usuarios = usuarios;
    };
    DataStore.prototype.updateAll = function (payload) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!LoginStoreModule.logado) return [3 /*break*/, 5];
                        return [4 /*yield*/, store.dispatch('updateProdutos', payload)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch('updateOrigens', payload)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch('updateUsuarios', payload)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, store.dispatch('refreshNotificacao', payload)];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, Promise.resolve(true)];
                    case 5: return [2 /*return*/, Promise.resolve(false)];
                }
            });
        });
    };
    DataStore.prototype.updateUsuarios = function (payload) {
        var clsApolloClient = new ClsApolloClient();
        var query = "\n        getUsuarios {\n          idUsuario\n          nome\n        }\n    ";
        return clsApolloClient.query(payload, query, 'getUsuarios').then(function (rs) {
            return rs;
        }).catch(function (err) {
            console.log('ERRO: ', err);
            return [];
        });
    };
    DataStore.prototype.updateProdutos = function (payload) {
        var clsApolloClient = new ClsApolloClient();
        var getProduto = "\n        getProdutos {\n          idProduto\n          descricao\n          ativo\n        }\n    ";
        return clsApolloClient.query(payload, getProduto, 'getProdutos').then(function (rs) {
            return rs;
        }).catch(function (err) {
            console.log('ERRO: ', err);
            return [];
        });
    };
    DataStore.prototype.updateOrigens = function (payload) {
        var clsApolloClient = new ClsApolloClient();
        var getOrigem = "\n        getOrigens {\n          idOrigem\n          descricao\n          ativa\n        }\n    ";
        return clsApolloClient.query(payload, getOrigem, 'getOrigens').then(function (rs) {
            return rs;
        }).catch(function (err) {
            console.log('ERRO: ', err);
            return [];
        });
    };
    __decorate([
        Mutation
    ], DataStore.prototype, "updateOrigemMutation", null);
    __decorate([
        Mutation
    ], DataStore.prototype, "updateProdutoMutation", null);
    __decorate([
        Mutation
    ], DataStore.prototype, "updateUsuarioMutation", null);
    __decorate([
        Action
    ], DataStore.prototype, "updateAll", null);
    __decorate([
        Action({ commit: 'updateUsuarioMutation' })
    ], DataStore.prototype, "updateUsuarios", null);
    __decorate([
        Action({ commit: 'updateProdutoMutation' })
    ], DataStore.prototype, "updateProdutos", null);
    __decorate([
        Action({ commit: 'updateOrigemMutation' })
    ], DataStore.prototype, "updateOrigens", null);
    DataStore = __decorate([
        Module({ dynamic: true, store: store, name: 'data' })
    ], DataStore);
    return DataStore;
}(VuexModule));
export var DataStoreModule = getModule(DataStore, store);
