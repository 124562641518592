export var StatusRole;
(function (StatusRole) {
    StatusRole[StatusRole["NOVO"] = 10] = "NOVO";
    StatusRole[StatusRole["TRATATIVA"] = 20] = "TRATATIVA";
    StatusRole[StatusRole["OPORTUNIDADE"] = 25] = "OPORTUNIDADE";
    StatusRole[StatusRole["NEGOCIACAO"] = 30] = "NEGOCIACAO";
    StatusRole[StatusRole["NAO_ATENDE"] = 40] = "NAO_ATENDE";
    StatusRole[StatusRole["CURSO_GRATIS"] = 45] = "CURSO_GRATIS";
    StatusRole[StatusRole["INCORRETO"] = 50] = "INCORRETO";
    StatusRole[StatusRole["SEM_INTERESSE"] = 60] = "SEM_INTERESSE";
    StatusRole[StatusRole["CONTATO_FUTURO"] = 70] = "CONTATO_FUTURO";
    StatusRole[StatusRole["PRE_MATRICULA"] = 75] = "PRE_MATRICULA";
    StatusRole[StatusRole["MATRICULA"] = 80] = "MATRICULA";
})(StatusRole || (StatusRole = {}));
export var TipoUsuarioRole;
(function (TipoUsuarioRole) {
    TipoUsuarioRole[TipoUsuarioRole["ADMIN"] = 0] = "ADMIN";
    TipoUsuarioRole[TipoUsuarioRole["SUPERVISOR"] = 1] = "SUPERVISOR";
    TipoUsuarioRole[TipoUsuarioRole["VENDEDOR"] = 2] = "VENDEDOR";
    TipoUsuarioRole[TipoUsuarioRole["USUARIO"] = 3] = "USUARIO";
    TipoUsuarioRole[TipoUsuarioRole["FINANCEIRO"] = 4] = "FINANCEIRO";
})(TipoUsuarioRole || (TipoUsuarioRole = {}));
// Revisado em Utilização...
export var StatusTiposUsuariosPermitidoAlterarStatusNovoCliente = [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR];
export var StatusPermitidoVendedorNovosClientes = [StatusRole.TRATATIVA, StatusRole.OPORTUNIDADE, StatusRole.NEGOCIACAO, StatusRole.NAO_ATENDE, StatusRole.CURSO_GRATIS, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.PRE_MATRICULA];
// A Revisar
export var StatusTravadoParaSupervisor = [StatusRole.NEGOCIACAO, StatusRole.OPORTUNIDADE, StatusRole.PRE_MATRICULA];
export var StatusFecharLeadRole = [StatusRole.NAO_ATENDE, StatusRole.CURSO_GRATIS, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.MATRICULA];
export var StatusRoleDescricao = [
    { idStatus: StatusRole.NOVO, descricao: 'Novo' },
    { idStatus: StatusRole.TRATATIVA, descricao: 'Tratativa' },
    { idStatus: StatusRole.OPORTUNIDADE, descricao: 'Oportunidade' },
    { idStatus: StatusRole.NEGOCIACAO, descricao: 'Negociação' },
    { idStatus: StatusRole.NAO_ATENDE, descricao: 'Não Atende' },
    { idStatus: StatusRole.CURSO_GRATIS, descricao: 'Curso Grátis' },
    { idStatus: StatusRole.INCORRETO, descricao: 'Incorreto' },
    { idStatus: StatusRole.SEM_INTERESSE, descricao: 'Sem Interesse' },
    { idStatus: StatusRole.CONTATO_FUTURO, descricao: 'Contato Futuro' },
    { idStatus: StatusRole.PRE_MATRICULA, descricao: 'Pré Matrícula' },
    { idStatus: StatusRole.MATRICULA, descricao: 'Matrícula' }
];
export var CanalRole;
(function (CanalRole) {
    CanalRole[CanalRole["SITE"] = 1] = "SITE";
    CanalRole[CanalRole["EMAIL"] = 2] = "EMAIL";
    CanalRole[CanalRole["WHATSAPP"] = 3] = "WHATSAPP";
    CanalRole[CanalRole["VISITA"] = 4] = "VISITA";
    CanalRole[CanalRole["TELEFONE"] = 5] = "TELEFONE";
    CanalRole[CanalRole["INSTAGRAM"] = 6] = "INSTAGRAM";
    CanalRole[CanalRole["FACEBOOK"] = 7] = "FACEBOOK";
})(CanalRole || (CanalRole = {}));
export var CanalRoleDescricao = [
    { idCanal: CanalRole.SITE, descricao: 'Site' },
    { idCanal: CanalRole.EMAIL, descricao: 'Email' },
    { idCanal: CanalRole.WHATSAPP, descricao: 'Whatsapp' },
    { idCanal: CanalRole.VISITA, descricao: 'Visita' },
    { idCanal: CanalRole.TELEFONE, descricao: 'Telefone' },
    { idCanal: CanalRole.INSTAGRAM, descricao: 'Instagram' },
    { idCanal: CanalRole.FACEBOOK, descricao: 'Facebook' }
];
export var TipoUsuarioRoleDescricao = [
    { idTipo: TipoUsuarioRole.VENDEDOR, descricao: 'Vendedor' },
    { idTipo: TipoUsuarioRole.USUARIO, descricao: 'Usuário' },
    { idTipo: TipoUsuarioRole.SUPERVISOR, descricao: 'Supervisor' },
    { idTipo: TipoUsuarioRole.ADMIN, descricao: 'Administrador' }
];
export var TipoLeadRole;
(function (TipoLeadRole) {
    TipoLeadRole[TipoLeadRole["ATIVO"] = 1] = "ATIVO";
    TipoLeadRole[TipoLeadRole["RECEPTIVO"] = 2] = "RECEPTIVO";
})(TipoLeadRole || (TipoLeadRole = {}));
export var TipoLeadRoleDescricao = [
    { idTipo: TipoLeadRole.ATIVO, descricao: 'Ativo' },
    { idTipo: TipoLeadRole.RECEPTIVO, descricao: 'Receptivo' }
];
export var ClienteUpdateRole;
(function (ClienteUpdateRole) {
    ClienteUpdateRole[ClienteUpdateRole["NOVO"] = 1] = "NOVO";
    ClienteUpdateRole[ClienteUpdateRole["EXISTENTE_SEM_LEAD"] = 2] = "EXISTENTE_SEM_LEAD";
    ClienteUpdateRole[ClienteUpdateRole["EXISTENTE_COM_LEAD"] = 3] = "EXISTENTE_COM_LEAD";
})(ClienteUpdateRole || (ClienteUpdateRole = {}));
