import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { mask } from 'vue-the-mask';
var FORMATO_VALIDACAO = 'yyyy-MM-dd HH:mm:ss';
var FORMATO_EXIBICAO = 'dd/MM/yyyy HH:mm:ss';
var FORMATO_DATA = 'yyyy-MM-dd';
var FORMATO_HORA = 'HH:mm:ss';
var inputDateTime = /** @class */ (function (_super) {
    __extends(inputDateTime, _super);
    function inputDateTime() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dateTemp = '';
        _this.timeTemp = '';
        _this.exibir = false;
        return _this;
    }
    Object.defineProperty(inputDateTime.prototype, "dataAtual", {
        get: function () {
            return DateTime.fromFormat(this.dateTemp.concat(' ').concat(this.timeTemp), FORMATO_VALIDACAO);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(inputDateTime.prototype, "dataFormatada", {
        get: function () {
            return this.dataAtual.isValid ? this.dataAtual.toFormat(FORMATO_EXIBICAO) : '';
        },
        enumerable: false,
        configurable: true
    });
    inputDateTime.prototype.emitInputEvent = function () {
        this.$emit('input', this.dataAtual.toISO());
    };
    inputDateTime.prototype.onChangeValue = function (newValue, oldValue) {
        if (this.value) {
            var data = DateTime.fromISO(this.value);
            //let data: DateTime = DateTime.fromISO( this.value, { zone: 'utc' } )
            if (data.isValid) {
                this.dateTemp = data.toFormat(FORMATO_DATA);
                this.timeTemp = data.toFormat(FORMATO_HORA);
            }
            else {
                this.btLimparTudo();
            }
        }
        else {
            this.btLimparTudo();
        }
        // console.log( 'oldValue', oldValue, 'newValue', newValue )
        // console.log( 'DataFormatada', this.dataFormatada, 'dateTemp', this.dateTemp, 'timeTemp', this.timeTemp )
    };
    inputDateTime.prototype.mounted = function () {
    };
    inputDateTime.prototype.btLimparHora = function () {
        this.timeTemp = '';
    };
    inputDateTime.prototype.btLimparTudo = function () {
        this.timeTemp = '';
        this.dateTemp = '';
    };
    inputDateTime.prototype.btConfirmar = function () {
        if (this.dataAtual.isValid) {
            this.exibir = false;
            this.emitInputEvent();
        }
    };
    __decorate([
        Prop({ default: 'Data' })
    ], inputDateTime.prototype, "label", void 0);
    __decorate([
        Prop({ default: '' })
    ], inputDateTime.prototype, "value", void 0);
    __decorate([
        Watch('value')
    ], inputDateTime.prototype, "onChangeValue", null);
    inputDateTime = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], inputDateTime);
    return inputDateTime;
}(Vue));
export default inputDateTime;
