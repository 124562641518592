import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '../../utils/ClsApolloClient';
import { LoginStoreModule } from '@/store/modules/LoginStore';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.status = StatusFormType.inicial;
        return _this;
    }
    app.prototype.mounted = function () {
        this.logout();
    };
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    app.prototype.logout = function () {
        var _this = this;
        var clsApolloClient = new ClsApolloClient();
        var logout = "\n        logout {\n          ok\n          mensagem\n        }\n      ";
        this.status = StatusFormType.processando;
        clsApolloClient.mutation(this, logout, 'logout', 'Saindo da Aplicação....').then(function (rs) {
            if (rs.ok) {
                LoginStoreModule.logout();
                _this.$router.push('/Login');
            }
            else {
                _this.status = StatusFormType.inicial;
            }
        }).catch(function (err) {
            console.log('ERRO....', err);
        });
    };
    app = __decorate([
        Component
    ], app);
    return app;
}(Vue));
export default app;
