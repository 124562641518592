var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Primeira Parcela em Atraso")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('input-date-range',{attrs:{"label":"Primeiro Vencimento em","range":"","clearable":""},model:{value:(_vm.pesquisa.periodo),callback:function ($$v) {_vm.$set(_vm.pesquisa, "periodo", $$v)},expression:"pesquisa.periodo"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":"","tabindex":"-1","disabled":!_vm.pesquisa.periodo.length},on:{"click":_vm.btDownloadPDF}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","tabindex":"-1","disabled":!_vm.pesquisa.periodo.length},on:{"click":_vm.btPesquisar}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 cssImpressao",attrs:{"headers":_vm.cabecalhos,"items":_vm.registros},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Parcelas em Atraso")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.Valor",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Valor.toFixed(2)))])]}},{key:"item.FoneComercial",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLinkWhatsAPP(item.FoneComercial, item))}},[_vm._v(_vm._s(_vm.getLinkWhatsAPP(item.FoneComercial)))])]}},{key:"item.FoneRecado",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLinkWhatsAPP(item.FoneRecado, item))}},[_vm._v(_vm._s(_vm.getLinkWhatsAPP(item.FoneRecado)))])]}},{key:"item.FoneResidencial",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLinkWhatsAPP(item.FoneResidencial, item))}},[_vm._v(_vm._s(_vm.getLinkWhatsAPP(item.FoneResidencial)))])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem Informações para Relatório")])]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }