import { __decorate, __extends } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { validadoresFrontEnd } from 'zlib-utils';
var ClienteCadastro = /** @class */ (function (_super) {
    __extends(ClienteCadastro, _super);
    function ClienteCadastro() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ClienteCadastro.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { } })
    ], ClienteCadastro.prototype, "editedItem", void 0);
    ClienteCadastro = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], ClienteCadastro);
    return ClienteCadastro;
}(Vue));
export default ClienteCadastro;
