import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var WhatsAppConversasCls = /** @class */ (function (_super) {
    __extends(WhatsAppConversasCls, _super);
    function WhatsAppConversasCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagem = "";
        _this.items = [
            { header: 'Today' },
            {
                mensagem: 'Esta é a Mensgem Enviada',
                enviada: true,
            },
            {
                mensagem: 'Esta é a Mensgem Recebida',
                enviada: false,
            },
            {
                mensagem: 'Esta é a Mensgem Recebida',
                enviada: false,
            },
            {
                mensagem: 'Esta é a Mensgem Enviada',
                enviada: true,
            },
        ];
        return _this;
    }
    WhatsAppConversasCls = __decorate([
        Component
    ], WhatsAppConversasCls);
    return WhatsAppConversasCls;
}(Vue));
export default WhatsAppConversasCls;
