import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import inputDateRange from '@/components/inputDateRange.vue';
import ClsUtils, { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
var RelPrimeiraParcelaEmAtraso = /** @class */ (function (_super) {
    __extends(RelPrimeiraParcelaEmAtraso, _super);
    function RelPrimeiraParcelaEmAtraso() {
        /*
      
        private get DataStoreModule (): StateDataInterface { return DataStoreModule }
        
          private get getRoles (): typeof getRoles {
            return getRoles
          }
      
        */
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pesquisa = {
            periodo: []
        };
        _this.registros = [];
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'IDMatricula', value: 'IDMatricula' },
            { text: 'Nome', value: 'Nome' },
            { text: 'Residencial', value: 'FoneResidencial' },
            { text: 'Comercial', value: 'FoneComercial' },
            { text: 'Recado', value: 'FoneRecado' },
            { text: 'Parcela', value: 'NumeroDocumento' },
            { text: 'Vencimento', value: 'Vencimento' },
            { text: 'Valor', value: 'Valor' }
        ];
        return _this;
    }
    RelPrimeiraParcelaEmAtraso.prototype.btPesquisar = function () {
        this.getRelatorio();
    };
    RelPrimeiraParcelaEmAtraso.prototype.getRelatorio = function () {
        var _this = this;
        var query = "\n      relFinanceiroPrimeiraParcela(criterio: " + clsUtils.ConverterEmGql(clsUtils.removeNullFromObject(this.pesquisa)) + ") {\n        IDMatricula\n        Vencimento\n        Valor\n        Nome\n        FoneResidencial\n        FoneComercial\n        FoneRecado\n        NumeroDocumento\n      }\n    ";
        this.clsApollo.query(this, query, 'relFinanceiroPrimeiraParcela', 'Aguarde...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    RelPrimeiraParcelaEmAtraso.prototype.btDownloadPDF = function () {
        var _this = this;
        var query = "\n      relFinanceiroPrimeiraParcelaPDF(criterio: " + clsUtils.ConverterEmGql(clsUtils.removeNullFromObject(this.pesquisa)) + ")\n    ";
        this.clsApollo.query(this, query, 'relFinanceiroPrimeiraParcelaPDF', 'Aguarde...').then(function (rs) {
            var link = document.createElement('a');
            link.href = 'data:application/octet-stream;base64,' + rs;
            link.download = "Primeira Parcela ".concat(" - ").concat(_this.pesquisa.periodo[0]).concat(' - ').concat(_this.pesquisa.periodo[1]).concat(".pdf");
            link.click();
        }).catch(function (err) {
            console.log('erro: ', err);
        });
        // let blob = new Blob( [rsEventoTOTVS], { type: 'application/txt' } )
        // let url = window.URL.createObjectURL( blob )
        /*
        // Download TXT
        const link = document.createElement( 'a' )
        link.setAttribute( 'href', 'data:text/plain;charset=utf-8,' + encodeURIComponent( this.csv ) );
        link.setAttribute( 'download', 'PrimeiraParcela.csv' );
        link.style.display = 'none'
        document.body.appendChild( link )
        link.click()
        document.body.removeChild( link )
        */
    };
    RelPrimeiraParcelaEmAtraso.prototype.getLinkWhatsAPP = function (fone, registro) {
        var primeiroNome = registro && registro.Nome.length > 0 && registro.Nome.includes(' ') ? registro.Nome.substring(0, registro.Nome.indexOf(' ')) : '';
        var primeiraFrase = 'Olá, meu nome é Lídia.%0a%0a';
        if (primeiroNome.length > 0) {
            primeiroNome = primeiroNome.substring(0, 1).concat(primeiroNome.toLocaleLowerCase().substring(1));
            primeiraFrase = "Oi *" + primeiroNome + "*, meu nome \u00E9 *L\u00EDdia*.%0a%0a";
        }
        var textoWhatsAPP = !registro ? '' : "\n    " + primeiraFrase + "\n    Sou aqui da *Fleek Cursos*.%0a%0a\n    Tudo bem com vc?%0a%0a\n    Quero conversar com vc a respeito desta parcela:%0a\n    Vencimento: *" + registro.Vencimento + "*%0a\n    Valor Original: *" + registro.Valor.toFixed(2) + "*%0a\n    Parcela: *" + registro.NumeroDocumento + "*%0a";
        var clsUtils = new ClsUtils();
        var tmpOriginal = fone;
        var retorno = tmpOriginal;
        if (typeof fone == 'string' && fone.length > 0) {
            fone = clsUtils.getSomenteNumeros(fone);
            if (fone.length == 8) {
                fone = '379'.concat(fone);
            }
            else if (fone.length == 9) {
                fone = '37'.concat(fone);
            }
            else if (fone.length == 10) {
                fone = fone.substring(0, 2).concat('9').concat(fone.substring(2));
            }
            // https://web.whatsapp.com/send/?phone=%2B5537988332585&text&type=phone_number&app_absent=0
            if (fone.length == 11) {
                // retorno = '+55'.concat( fone )
                // retorno = '<a href="http://wa.me/+55'.concat( fone ).concat( '"' ).concat( 'target="_blank" rel="noopener noreferrer">' ).concat( tmpOriginal ).concat( '</a>' )
                // 'https://web.whatsapp.com/send/?phone=55'.concat( this.clsUtils.getSomenteNumeros( this.txtWhatsAPP ) ).concat( '&text&type=phone_number&app_absent=0' )
                retorno = '<a href="https://web.whatsapp.com/send/?phone=55'.concat(fone).concat('&text=').concat(textoWhatsAPP).concat('&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">');
                retorno = retorno.concat(tmpOriginal).concat('</a>');
            }
        }
        return retorno;
    };
    RelPrimeiraParcelaEmAtraso = __decorate([
        Component({
            components: {
                inputDateRange: inputDateRange
            }
        })
    ], RelPrimeiraParcelaEmAtraso);
    return RelPrimeiraParcelaEmAtraso;
}(Vue));
export default RelPrimeiraParcelaEmAtraso;
