export var EMDESENVOLVIMENTO = false;
export var VERSAO_SISTEMA = 'v1.58';
export var PORT_ENDPOINT = 4001;
export var PORT_WEB_SERVICE = 8085;
export var IP_ENDPOINT = EMDESENVOLVIMENTO ? 'localhost' : 'crmvendas.fleekcursos.com.br';
export var ENDPOINT_GRAPHQL = 'http'.concat(EMDESENVOLVIMENTO ? '://' : 's://').concat(IP_ENDPOINT).concat(':').concat(PORT_ENDPOINT.toString()).concat('/graphql');
export var TAMANHO_MAXIMO_PAYLOAD = 100 * 1024;
export var ID_USUARIO_SITE = 8;
export var ID_ORIGEM_SITE = 6;
export var ID_ORIGEM_INDICACAO = 10;
export var EMAIL_LEADS_TO = process.env.EMAIL_LEADS_TO || EMDESENVOLVIMENTO ? 'zanattafleek@gmail.com' : 'coordenacao@fleekcursos.com.br';
