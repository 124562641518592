import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getRoles } from '@/utils/dataTypes';
import { DataStoreModule } from '@/store/modules/DataStore';
import { getFormats } from '@/utils/FormatarDados';
var ClienteAgendamentos = /** @class */ (function (_super) {
    __extends(ClienteAgendamentos, _super);
    function ClienteAgendamentos() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ClienteAgendamentos.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteAgendamentos.prototype, "leadAgendas", {
        get: function () {
            if (this.lead && this.lead.leadAgendas) {
                return this.lead.leadAgendas;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteAgendamentos.prototype, "getRoles", {
        get: function () {
            return getRoles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClienteAgendamentos.prototype, "getFormats", {
        get: function () {
            return getFormats;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { leadAgendas: []; } })
    ], ClienteAgendamentos.prototype, "lead", void 0);
    ClienteAgendamentos = __decorate([
        Component
    ], ClienteAgendamentos);
    return ClienteAgendamentos;
}(Vue));
export default ClienteAgendamentos;
