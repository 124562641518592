import { __decorate, __extends } from "tslib";
import { TipoUsuarioRole } from '@/interfaces/backend/RolesInterfaces';
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '..';
// @Module( { namespaced: true, name: 'login' } )
var LoginStore = /** @class */ (function (_super) {
    __extends(LoginStore, _super);
    function LoginStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.idUsuario = 0;
        _this.logado = false;
        _this.nome = '';
        _this.token = '';
        _this.tipo = -1;
        _this.isAdmin = false;
        _this.isVendedor = false;
        _this.isSupervisor = false;
        _this.isUsuario = false;
        return _this;
    }
    LoginStore.prototype.login = function (usuarioLogado) {
        this.idUsuario = usuarioLogado.idUsuario;
        this.logado = true;
        this.nome = usuarioLogado.nome;
        this.token = usuarioLogado.token;
        this.tipo = usuarioLogado.tipo;
        this.isAdmin = TipoUsuarioRole.ADMIN == usuarioLogado.tipo;
        this.isVendedor = TipoUsuarioRole.VENDEDOR == usuarioLogado.tipo;
        this.isSupervisor = TipoUsuarioRole.SUPERVISOR == usuarioLogado.tipo;
        this.isUsuario = TipoUsuarioRole.USUARIO == usuarioLogado.tipo;
    };
    LoginStore.prototype.logar = function (usuarioLogado) {
        return usuarioLogado;
    };
    LoginStore.prototype.logout = function () {
        this.idUsuario = 0;
        this.logado = false;
        this.nome = '';
        this.token = '';
        this.tipo = -1;
    };
    LoginStore.prototype.setToken = function (token) {
        this.token = token;
    };
    __decorate([
        Mutation
    ], LoginStore.prototype, "login", null);
    __decorate([
        Action({ commit: 'login' })
    ], LoginStore.prototype, "logar", null);
    __decorate([
        Mutation
    ], LoginStore.prototype, "logout", null);
    __decorate([
        Mutation
    ], LoginStore.prototype, "setToken", null);
    LoginStore = __decorate([
        Module({ dynamic: true, store: store, name: 'loginStore' })
    ], LoginStore);
    return LoginStore;
}(VuexModule));
export var LoginStoreModule = getModule(LoginStore, store);
