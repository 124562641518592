import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DataStoreModule } from '@/store/modules/DataStore';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import inputDateTime from '@/components/inputDateTime.vue';
import { ClsReset } from '../ClsResets';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
var leadNovoAgendamentoCls = /** @class */ (function (_super) {
    __extends(leadNovoAgendamentoCls, _super);
    function leadNovoAgendamentoCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.editedItem = (new ClsReset()).novoAgendamento;
        _this.tmpRegistro = __assign({}, _this.editedItem);
        _this.status = StatusFormType.editando;
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    Object.defineProperty(leadNovoAgendamentoCls.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(leadNovoAgendamentoCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    leadNovoAgendamentoCls.prototype.onChangeIdLead = function (newValue, oldValue) {
        if (this.editedItem.idLead != newValue || newValue != oldValue) {
            this.resetItem();
        }
    };
    Object.defineProperty(leadNovoAgendamentoCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    leadNovoAgendamentoCls.prototype.mounted = function () {
        this.resetItem();
    };
    leadNovoAgendamentoCls.prototype.resetItem = function () {
        this.editedItem = (new ClsReset()).novoAgendamento;
        this.editedItem.idLead = this.idLead;
        this.tmpRegistro = __assign({}, this.editedItem);
    };
    leadNovoAgendamentoCls.prototype.btConfirmar = function () {
        var _this = this;
        var novoAgendamento = "\n      novoAgendamento(dados: " + clsUtils.ConverterEmGql(this.editedItem) + ") {\n        ok\n        mensagem\n      }    \n    ";
        this.clsApollo.mutation(this, novoAgendamento, 'novoAgendamento', 'Agendando...').then(function (rs) {
            if (rs.ok) {
                _this.resetItem();
                _this.$emit('refresh');
            }
        });
    };
    leadNovoAgendamentoCls.prototype.btCancelar = function () {
        this.resetItem();
    };
    __decorate([
        Prop({ default: 0 })
    ], leadNovoAgendamentoCls.prototype, "idLead", void 0);
    __decorate([
        Watch('idLead')
    ], leadNovoAgendamentoCls.prototype, "onChangeIdLead", null);
    leadNovoAgendamentoCls = __decorate([
        Component({
            components: {
                inputDateTime: inputDateTime
            }
        })
    ], leadNovoAgendamentoCls);
    return leadNovoAgendamentoCls;
}(Vue));
export default leadNovoAgendamentoCls;
