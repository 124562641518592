import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import inputDate from '@/components/inputDate.vue';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { DataStoreModule } from '@/store/modules/DataStore';
import { mask } from 'vue-the-mask';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import moment from 'moment';
var AgendaCls = /** @class */ (function (_super) {
    __extends(AgendaCls, _super);
    function AgendaCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.temSuporteContatos = ('contacts' in navigator && 'ContactsManager' in window);
        _this.clsApollo = new ClsApolloClient();
        _this.consultores = [];
        //@ts-ignore
        _this.dados = EMDESENVOLVIMENTO ? {
            consultorResponsavel: 1,
            nomeResponsavel: "Marcelo Zanatta teste",
            dataNascimento: "24/06/1974",
            foneContato: "37988118785",
            contatos: []
        } : {
            consultorResponsavel: 0,
            nomeResponsavel: "",
            dataNascimento: new Date(''),
            foneContato: "",
            contatos: []
        };
        _this.debug = "";
        return _this;
    }
    Object.defineProperty(AgendaCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AgendaCls.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AgendaCls.prototype, "validForm", {
        get: function () {
            var retorno = this.dados.consultorResponsavel != 0 &&
                this.dados.nomeResponsavel.length >= 5 &&
                this.dados.foneContato.length >= 14 &&
                moment(this.dados.dataNascimento, 'DD/MM/YYYY', true).isValid();
            /*
                if ( retorno ) {
                  this.dados.dataNascimento = moment( this.dados.dataNascimento, 'DD/MM/YYYY', true ).toDate()
                }
            */
            return retorno;
        },
        enumerable: false,
        configurable: true
    });
    AgendaCls.prototype.mounted = function () {
        this.getConsultores();
    };
    AgendaCls.prototype.btConfirmar = function () {
        var _this = this;
        if (EMDESENVOLVIMENTO) {
            this.gravarIndicacoes([{
                    address: ["Av. 21 de Abril, 504"],
                    email: ["zanatta@peopledivinopolis.com.br"],
                    name: ["Marcelo Zanatta"],
                    tel: ["037988118785"]
                },
                {
                    address: ["Av. 21 de Abril, 504", "Av. 21 de Abril, 506"],
                    email: ["andreazanatta@peopledivinopolis.com.br", "Andrea@fleekcursos.com.br"],
                    name: ["Andréa Zanatta", "Andréia C Zanatta"],
                    tel: ["037988118785", "03735121919", "03732129399"]
                }]);
        }
        else {
            //@ts-ignore
            navigator.contacts.select(['address', 'email', 'name', 'tel'], { multiple: true }).then(function (rsContacts) {
                _this.gravarIndicacoes(rsContacts);
            });
        }
    };
    AgendaCls.prototype.getConsultores = function () {
        var _this = this;
        var getConsultores = "\n      getConsultores {\n          idUsuario\n          nome\n      }\n    ";
        this.clsApollo.query(this, getConsultores, 'getConsultores', 'Verificando...').then(function (rs) {
            _this.consultores = rs;
        });
    };
    AgendaCls.prototype.gravarIndicacoes = function (contatos) {
        var _this = this;
        if (contatos === void 0) { contatos = []; }
        var objEnvio = __assign(__assign({}, this.dados), { contatos: contatos });
        objEnvio.dataNascimento = moment(objEnvio.dataNascimento, "DD/MM/YYYY").toISOString();
        var gravarIndicacoes = "\n      gravarIndicacoes(dados: " + clsUtils.ConverterEmGql(objEnvio) + ") {\n        ok\n        mensagem\n      }\n    ";
        // this.debug = gravarIndicacoes
        this.clsApollo.mutation(this, gravarIndicacoes, 'gravarIndicacoes', 'Gravando...').then(function (rs) {
            _this.$router.push("/IndicacaoObrigado/".concat(rs.ok ? 'true' : 'false'));
        });
    };
    AgendaCls = __decorate([
        Component({
            components: {
                inputDate: inputDate
            },
            directives: {
                mask: mask
            }
        })
    ], AgendaCls);
    return AgendaCls;
}(Vue));
export default AgendaCls;
