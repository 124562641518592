import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var WhatsAppConversasCls = /** @class */ (function (_super) {
    __extends(WhatsAppConversasCls, _super);
    function WhatsAppConversasCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.benched = 0;
        _this.xx_items = [
            { header: 'Today' },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                title: 'Nome do Lead',
                subtitle: "<span class=\"text--primary\">N\u00FAmero Telefone</span>Nome Contato",
            },
            { divider: true, inset: true },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
                subtitle: "<span class=\"text--primary\">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.",
            },
            { divider: true, inset: true },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                title: 'Oui oui',
                subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
            },
            { divider: true, inset: true },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                title: 'Birthday gift',
                subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
            },
            { divider: true, inset: true },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                title: 'Recipe to try',
                subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
            },
        ];
        return _this;
    }
    Object.defineProperty(WhatsAppConversasCls.prototype, "items", {
        get: function () { return Array.from({ length: this.length }, function (k, v) { return v + 1; }); },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WhatsAppConversasCls.prototype, "length", {
        get: function () { return 100; },
        enumerable: false,
        configurable: true
    });
    WhatsAppConversasCls = __decorate([
        Component
    ], WhatsAppConversasCls);
    return WhatsAppConversasCls;
}(Vue));
export default WhatsAppConversasCls;
