import { __decorate, __extends, __spreadArrays } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { MENU } from '@/config/Menu';
import { NotificacaoStoreModule } from '@/store/modules/NotificacaoStore';
var MenuInicial = /** @class */ (function (_super) {
    __extends(MenuInicial, _super);
    function MenuInicial() {
        var _this = _super.call(this) || this;
        if (!LoginStoreModule.logado) {
            _this.$router.push('Login');
        }
        return _this;
    }
    Object.defineProperty(MenuInicial.prototype, "MENU", {
        get: function () {
            return MENU;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuInicial.prototype, "LoginStoreModule", {
        get: function () {
            return LoginStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    MenuInicial.prototype.getFilhosMenu = function (oque) {
        var _this = this;
        var retorno = [];
        oque.forEach(function (valor, indice) {
            if (valor.filhos && valor.permissoes.includes(LoginStoreModule.tipo)) {
                retorno = __spreadArrays(retorno, _this.getFilhosMenu(valor.filhos));
            }
            else if (valor.permissoes.includes(LoginStoreModule.tipo)) {
                retorno.push(valor);
            }
        });
        return retorno;
    };
    MenuInicial.prototype.btClickMenu = function (destino) {
        NotificacaoStoreModule.refreshNotificacao(this);
        if (destino && this.$router.currentRoute.path != destino) {
            this.$router.push(destino);
        }
    };
    MenuInicial = __decorate([
        Component
    ], MenuInicial);
    return MenuInicial;
}(Vue));
export default MenuInicial;
