import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import inputDateRange from '@/components/inputDateRange.vue';
import { DataStoreModule } from '@/store/modules/DataStore';
import { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import _ from 'lodash';
import { getRoles } from '@/utils/dataTypes';
import { StatusRole } from '@/interfaces/backend/RolesInterfaces';
var RelProdutividadeCls = /** @class */ (function (_super) {
    __extends(RelProdutividadeCls, _super);
    function RelProdutividadeCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pesquisa = {};
        _this.registros = [];
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'idUsuario', value: 'idUsuario' },
            { text: 'Novo', value: StatusRole.NOVO.toString() },
            { text: 'Tratativa', value: StatusRole.TRATATIVA.toString() },
            { text: 'Oportunidade', value: StatusRole.OPORTUNIDADE.toString() },
            { text: 'Negociação', value: StatusRole.NEGOCIACAO.toString() },
            { text: 'Não Atende', value: StatusRole.NAO_ATENDE.toString() },
            { text: 'Curso Grátis', value: StatusRole.CURSO_GRATIS.toString() },
            { text: 'Incorreto', value: StatusRole.INCORRETO.toString() },
            { text: 'Sem Interesse', value: StatusRole.SEM_INTERESSE.toString() },
            { text: 'Futuro', value: StatusRole.CONTATO_FUTURO.toString() },
            { text: 'Pré Matrícula', value: StatusRole.PRE_MATRICULA.toString() },
            { text: 'Matrícula', value: StatusRole.MATRICULA.toString() },
            { text: 'Total', value: 'Total' },
        ];
        return _this;
    }
    Object.defineProperty(RelProdutividadeCls.prototype, "DataStoreModule", {
        get: function () { return DataStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RelProdutividadeCls.prototype, "getRoles", {
        get: function () {
            return getRoles;
        },
        enumerable: false,
        configurable: true
    });
    RelProdutividadeCls.prototype.btPesquisar = function () {
        this.getRelatorio();
    };
    RelProdutividadeCls.prototype.getRelatorio = function () {
        // console.log( this.getRoles.usuario( 1 ) )
        var _this = this;
        if (this.pesquisa.periodoContato && this.pesquisa.periodoContato.length == 0) {
            delete this.pesquisa.periodoContato;
        }
        var query = "\n      relProdutividade(criterio: " + clsUtils.ConverterEmGql(clsUtils.removeNullFromObject(this.pesquisa)) + ") {\n        idUsuario\n        status\n        qtd\n      }\n    ";
        this.clsApollo.query(this, query, 'relProdutividade', 'Aguarde...').then(function (rs) {
            _this.registros = _this.totalizarRelatorio(rs);
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    RelProdutividadeCls.prototype.totalizarRelatorio = function (rs) {
        var obj = [];
        rs.forEach(function (element) {
            var indice = _.findIndex(obj, { idUsuario: element.idUsuario });
            if (indice < 0) {
                obj.push({ idUsuario: element.idUsuario });
                indice = obj.length - 1;
                obj[indice]['Total'] = 0;
            }
            obj[indice][element.status] = element.qtd;
            obj[indice]['Total'] += element.qtd;
        });
        return obj;
    };
    RelProdutividadeCls = __decorate([
        Component({
            components: {
                inputDateRange: inputDateRange
            }
        })
    ], RelProdutividadeCls);
    return RelProdutividadeCls;
}(Vue));
export default RelProdutividadeCls;
