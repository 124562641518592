import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '../../utils/ClsApolloClient';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var AlterarSenha = /** @class */ (function (_super) {
    __extends(AlterarSenha, _super);
    function AlterarSenha() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApolloClient = new ClsApolloClient();
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.exibirSenha = false;
        _this.registro = {
            senhaAtual: '',
            novaSenha: ''
        };
        return _this;
    }
    Object.defineProperty(AlterarSenha.prototype, "validadoresFrontEnd", {
        get: function () {
            return validadoresFrontEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AlterarSenha.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    AlterarSenha.prototype.btAlterarSenha = function () {
        var _this = this;
        //@ts-ignore
        if (this.$refs.form.validate()) {
            var mutation = "\n        alterarSenha(senha: \"" + this.registro.novaSenha + "\", idUsuario: " + LoginStoreModule.idUsuario + ", senhaAtual: \"" + this.registro.senhaAtual + "\") {\n          ok\n          mensagem\n        }\n      ";
            this.status = StatusFormType.processando;
            this.clsApolloClient.mutation(this, mutation, 'alterarSenha', 'Alterando Senha....').then(function (rs) {
                if (rs.ok) {
                    _this.$router.push("/Logout");
                }
                else {
                    MensagemStoreModule.exibirMensagem({
                        mensagem: rs.mensagem,
                        tipo: TipoMensagemType.erro,
                        titulo: 'Erro!!!'
                    });
                }
            });
        }
    };
    AlterarSenha = __decorate([
        Component
    ], AlterarSenha);
    return AlterarSenha;
}(Vue));
export default AlterarSenha;
